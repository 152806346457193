import { FormHelperText, TextFieldProps, useTheme } from '@mui/material';
import React, { FocusEventHandler } from 'react';
import currencyMap, { getCurrencySymbol } from 'util/currency';
import Label from '../Label';
import Select from 'ui/elements/form/Select/Select';
import DecimalInput from './DecimalInput';
import IconButton from 'ui/elements/icons/IconButton';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import styles from './styles.scss';
import classNames from 'classnames';

interface Props {
  onCurrencyChange: (value: string) => void;
  onChange: (value: string | number | null | undefined) => void;
  onBlur?: FocusEventHandler;
  error?: string;
  touched?: boolean;
  className?: string;
  currency: string;
  currencyError?: string;
  name: string;
  label?: string;
  value?: string | number | null;
  placeholder: string;
  nullable?: boolean;
}

type TextFieldProps2 = Omit<TextFieldProps, 'onChange' | 'error' | 'onBlur'>;

export default function CurrencyInput(props: TextFieldProps2 & Props) {
  const theme = useTheme();

  const handleClear = () => {
    props.onChange(null);
  };

  const handleChange = (val: string) => {
    if (props.nullable) {
      const numValue = parseFloat(val);
      props.onChange(isNaN(numValue) ? null : numValue);
    } else {
      props.onChange(parseFloat(val));
    }
  };

  return (
    <div
      className={classNames(styles.currencyInputGrid, props.className, {
        [styles.noLabel]: !props.label,
      })}
    >
      {props.label && (
        <Label htmlFor={props.name} style={{ gridArea: 'inputLabel' }}>
          {props.label}
        </Label>
      )}
      <Select
        style={{ gridArea: 'currency' }}
        name={`${props.name}-currency`}
        placeholder="Select currency"
        onChange={v => props.onCurrencyChange(v?.value ?? '')}
        value={{ label: getCurrencySymbol(props.currency), value: props.currency }}
        getOptionLabel={option => option.label ?? ''}
        options={Object.keys(currencyMap).map(v => ({
          value: v,
          label: v,
        }))}
        className={styles.currencySelect}
        error={props.touched && !!props.currencyError}
        isOptionEqualToValue={(option, value) => option.value === value.value}
      />
      <DecimalInput
        autoFocus={props.autoFocus}
        style={{ gridArea: 'input' }}
        name={props.name}
        value={props.value === null ? '' : props.value}
        onChange={handleChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        helperText={props.touched ? (props.error ?? props.currencyError ?? '') : ''}
        error={props.touched && (!!props.error || !!props.currencyError)}
        placeholder={props.placeholder}
        className={styles.textInput}
        suffix={
          props.nullable && (
            <IconButton sx={{ padding: theme.spacing(0.5) }} color="indigo" onClick={handleClear} aria-label="Close">
              <CloseIcon fontSize={'small'} />
            </IconButton>
          )
        }
        nullable={props.nullable}
      />
      <FormHelperText style={{ gridArea: 'helper-text' }}>{props.helperText}</FormHelperText>
    </div>
  );
}
