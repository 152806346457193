import React from 'react';
import { userUrls } from 'urls';
import { Link } from 'react-router-dom';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import DashboardMessage from '../messages/DashboardMessage';
import NetworkIcon from 'ui/elements/icons/NetworkIcon';

export default function ActivityFromNetworkEmptyState() {
  return (
    <Link to={userUrls.discover}>
      <DashboardMessage
        topElement={
          <IconAvatar color="blue-light" size={{ width: '60px', height: '60px' }}>
            <NetworkIcon style={{ fontSize: '30px' }} color="indigo"></NetworkIcon>
          </IconAvatar>
        }
      >
        <p>Activity from your network will appear here.</p>
      </DashboardMessage>
    </Link>
  );
}
