import React from 'react';
import SvgIcon, { IconProps } from './SvgIcon';

export default function MortarboardIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M2.067 8c0 1.341 8.097 5 9.988 5 1.892 0 9.988-3.659 9.988-5 0-1.343-8.096-5.001-9.988-5.001-1.891 0-9.988 3.658-9.988 5Z" />
      <path d="m6.062 11 1.25 5.8c.087.398.284.769.614 1.005 2.222 1.595 6.034 1.595 8.256 0 .33-.236.528-.607.613-1.005l1.252-5.8M20.547 9.5v7m0 0c-.791 1.447-1.141 2.222-1.496 3.501-.077.455-.016.684.297.888.127.083.28.112.432.112h1.518a.798.798 0 0 0 .458-.125c.29-.201.366-.422.287-.875-.312-1.188-.708-2-1.496-3.5Z" />
    </SvgIcon>
  );
}
