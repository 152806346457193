import React from 'react';
import styles from '../styles.scss';
import Logo, { ContextType } from 'ui/domain/Logo';
import { Divider, ListItem, ListItemIcon, ListItemText } from '@mui/material';

export default function NavigationLogoMobile({
  logoURL,
  name,
  onClick,
  type,
}: {
  logoURL?: string;
  name: string;
  onClick: () => void;
  type: ContextType;
}) {
  return (
    <>
      <Divider />
      <ListItem onClick={onClick} style={{ marginLeft: -9 }}>
        <ListItemIcon>
          <div className={styles.companyLogoWrapper}>
            <Logo url={logoURL} type={type} size={38} />
          </div>
        </ListItemIcon>
        <ListItemText style={{ paddingLeft: '9px' }}>
          <h3 className="text-h3">{name}</h3>
        </ListItemText>
      </ListItem>
      <Divider />
    </>
  );
}
