import ApiBase from 'apis';
import config from 'config';
import { Organization, OrganizationInviteDTO, Service, UpdateOrganizationDTO } from 'types/organization';
import { PaginationOptions } from 'types/api';
import { withPagination } from 'apis/utils';
import { RegisterOrganizationResultDTO } from 'types/company';
import { joinQueryParams } from 'util/urlUtils';
import { EventDTO, NewEventDTO, TinyEventUserInfo, UpdateEventDTO } from 'ui/domain/Event/types';
import { DocumentDownloadDTO, DocumentList } from 'types/documents';
import { baseEventUrl } from 'urls';

const baseUrl = config.COMPANIES_API_URL;
const api = ApiBase();

export default {
  getBySlug: (slug: string) => `${baseUrl}/organizations/${slug}`,
  getById: (id: UUID) => `${baseUrl}/organizations/${id}/tiny`,
  getPublic: (slug: string) => `${baseUrl}/organizations/profile/${slug}`,
  listAllUsersUrl: (organizationId: string) => `${baseUrl}/organizations/${organizationId}/users`,
  getVCsLookingForCompanies: () => `${baseUrl}/organizations/vcs`,
  listAllServices: () => api.get<Service[]>(`${baseUrl}/labels/services`),
  listAllServicesUrl: `${baseUrl}/labels/services`,
  listAllTypes: () => api.get<Service[]>(`${baseUrl}/labels/organization-types`),
  listAllTypesUrl: `${baseUrl}/labels/organization-types`,
  update: (organizationId: string, dto: UpdateOrganizationDTO) =>
    api.put<Organization>(`${baseUrl}/organizations/${organizationId}`, dto),
  getPendingInvite: (inviteCode: string, organizationId: string) =>
    `${baseUrl}/organizations/${organizationId}/invitations/${inviteCode}`,
  acceptInvite: (organizationId: string, inviteCode: string) =>
    api.post<OrganizationInviteDTO>(`${baseUrl}/organizations/${organizationId}/invitations/${inviteCode}/accept`, {}),
  retractInvite: (organizationId: string, inviteCode: string) =>
    api.post<OrganizationInviteDTO>(`${baseUrl}/organizations/${organizationId}/invitations/${inviteCode}/retract`, {}),
  listInvitesUrl: (
    organizationId: string,
    paginationOptions?: PaginationOptions,
    extraParams?: { [key: string]: any },
  ) => withPagination(`${baseUrl}/organizations/${organizationId}/invitations`, paginationOptions, extraParams),
  inviteUsers: (organizationId: string, emails: string[]) =>
    api.post(`${baseUrl}/organizations/${organizationId}/invitations`, { userEmails: emails }),
  listUrl: () => `${baseUrl}/organizations`,
  communityMembership: {
    leave: (organizationId: string, communitySlug: string) =>
      api.deleteRequest(`${baseUrl}/organizations/${organizationId}/communities/${communitySlug}`),
  },
  registerOrganization: (
    accessToken: string | undefined,
    body: { organization: { organizationName: string }; originUrl?: string },
    {
      communityCode,
    }: {
      communityCode?: string;
    },
  ) =>
    api.post<RegisterOrganizationResultDTO>(
      `${baseUrl}/organizations${joinQueryParams([['communityCode', communityCode]])}`,
      body,
      accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined,
    ),
  updateOrganizationVisibility: (organizationId: string, visible: boolean) =>
    api.put(`${baseUrl}/organizations/${organizationId}/visibility`, { visible: visible }),

  events: {
    create: (organizationId: UUID, payload: NewEventDTO) =>
      api.post<EventDTO>(`${baseEventUrl(organizationId)}`, payload),
    update: (organizationId: UUID, eventId: UUID, payload: UpdateEventDTO) =>
      api.put<UpdateEventDTO>(`${baseEventUrl(organizationId, eventId)}`, payload),
    delete: (organizationId: UUID, eventId: UUID) =>
      api.deleteRequest<EventDTO>(`${baseEventUrl(organizationId, eventId)}`),

    updateEventUserInfo: (organizationId: UUID, eventId: UUID, payload: TinyEventUserInfo) =>
      api.put<TinyEventUserInfo>(`${baseEventUrl(organizationId, eventId)}/users/me`, payload),

    listAttachments: (organizationId: UUID, eventId: UUID) =>
      api.get<DocumentList>(`${baseEventUrl(organizationId, eventId)}/documents`),
    download: (organizationId: UUID, eventId: UUID, documentId: UUID) =>
      api.get<DocumentDownloadDTO>(`${baseEventUrl(organizationId, eventId)}/documents/download/${documentId}`),
  },
};
