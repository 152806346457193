import { TinyCommunity } from 'types/company/community';
import { useCompanyOrCommunityDashboardData } from 'pages/Dashboard/useDashboardData';
import React, { useState } from 'react';
import Card from 'ui/views/cards/Card';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import { communityUrls } from 'urls';
import AddIcon from 'ui/elements/icons/AddIcon';
import CalendarIcon from 'ui/elements/icons/CalendarIcon';
import CompaniesIcon from 'ui/elements/icons/CompanyIcon';
import UserIcon from 'ui/elements/icons/UserIcon';
import Button from 'ui/elements/buttons/Button';
import InviteUserIcon from 'ui/elements/icons/InviteUserIcon';
import TurnRightIcon from 'ui/elements/icons/TurnRightIcon';
import InviteDialog from 'pages/Community/CommunityManagement/invite/InviteDialog';
import OnYourCompanyAgenda from 'pages/Dashboard/manage/OnYourCompanyAgenda';
import styled from '@emotion/styled';
import { halfSpacing } from 'ui/theme/themeConstants';
import { bluePlanetTheme } from 'ui/theme';
import { useCommunitySummary } from 'apis/CompanyAPI/communities/useCommunitySummary';
import Resources from 'util/resource/Resources';

export const ActionLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${halfSpacing};

  ${bluePlanetTheme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export default function ManageCommunity({ community }: { community: TinyCommunity }) {
  const { resource: dashboardData } = useCompanyOrCommunityDashboardData(community);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const { resource: summary } = useCommunitySummary(community.slug);

  return (
    <Resources resources={[dashboardData, summary]}>
      {([dashboardData, summary]) => (
        <>
          <div>
            <Card className="u-section-spacing-bottom">
              <ActionLinks>
                <LinkAsButton
                  url={communityUrls.overview(community.slug, 'updates', {
                    isPostingUpdate: true,
                  })}
                >
                  <AddIcon className="u-half-spacing-right" />
                  New post
                </LinkAsButton>
                <LinkAsButton color="greyLight" url={communityUrls.events.create(community.slug)}>
                  <CalendarIcon className="u-half-spacing-right" fontSize="small" />
                  Create event
                </LinkAsButton>
                {summary.configuration.companyMembersIsEnabled && (
                  <LinkAsButton color="greyLight" url={communityUrls.manageMembers.companies(community.slug)}>
                    <CompaniesIcon className="u-half-spacing-right" fontSize="small" />
                    Manage companies
                  </LinkAsButton>
                )}
                <LinkAsButton color="greyLight" url={communityUrls.manageMembers.users(community.slug)}>
                  <UserIcon className="u-half-spacing-right" fontSize="small" />
                  Manage users
                </LinkAsButton>
                <Button kind="primary" color="greyLight" onClick={() => setIsInviteDialogOpen(true)}>
                  <InviteUserIcon className="u-half-spacing-right" fontSize="small" />
                  Invite members
                </Button>
                <LinkAsButton color="greyLight" url={communityUrls.overview(community.slug)}>
                  <TurnRightIcon className="u-half-spacing-right" fontSize="small" />
                  Go to community
                </LinkAsButton>
              </ActionLinks>
              {isInviteDialogOpen && (
                <InviteDialog communitySlug={community.slug} onClose={() => setIsInviteDialogOpen(false)} />
              )}
            </Card>
          </div>
          <div>
            <OnYourCompanyAgenda
              messages={dashboardData.messages.filter(
                it =>
                  ![
                    'welcome-to-company-profile-as-new-company-admin',
                    'should-update-investors-as-company-admin',
                    'should-update-company-profile-as-company-admin',
                  ].includes(it.type),
              )} // We show these in to-do's instead
            />
          </div>
        </>
      )}
    </Resources>
  );
}
