import React, { useState } from 'react';
import useNotify from 'hooks/useNotify';
import ButtonList from 'ui/elements/buttons/ButtonList';
import SelectCloud from 'ui/elements/form/SelectCloud/SelectCloud';
import Button from 'ui/elements/buttons/Button';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import { Organization, OrganizationType } from 'types/organization';
import { useUpdateOrganization } from 'apis/OrganizationAPI/organizations/useUpdateOrganization';
import { notEmpty } from 'util/arrayUtils';

export default function OrganizationTypeForm({
  organizationTypes,
  organization,
  onComplete,
  onClose,
}: {
  organizationTypes: OrganizationType[];
  organization: Organization;
  onComplete: () => void;
  onClose: () => void;
}) {
  const { details } = organization;
  const notify = useNotify();

  const [selectedOrganizationTypes, setSelectedOrganizationTypes] = useState<string[]>(
    details.types.length > 0 ? details.types : [],
  );

  const [updateOrganization, isUpdatingOrganization] = useUpdateOrganization(organization, {
    onSuccess: () => {
      onComplete();
    },
    onFailure() {
      notify('error', 'Failed to update organization types');
    },
  });

  return (
    <>
      <Content>
        <SelectCloud
          options={organizationTypes}
          values={selectedOrganizationTypes.map(ot => ({ name: ot, id: ot, key: ot }))}
          onSelect={orgType => setSelectedOrganizationTypes([...selectedOrganizationTypes, orgType.name])}
          onRemove={orgType =>
            setSelectedOrganizationTypes(selectedOrganizationTypes.filter(ot => ot !== orgType.name))
          }
        />
      </Content>
      <DialogActions align="space-between">
        <ButtonList>
          <Button
            isLoading={isUpdatingOrganization}
            onClick={() =>
              updateOrganization({
                types: selectedOrganizationTypes
                  .map(ot => organizationTypes.find(({ name }) => name === ot)?.id)
                  .filter(notEmpty),
              })
            }
            kind="primary"
          >
            Next
          </Button>
        </ButtonList>
        <Button onClick={onClose} kind="tertiary" color="grey">
          Pick it up later
        </Button>
      </DialogActions>
    </>
  );
}
