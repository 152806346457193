import companyNavigation from 'domain/shared/Navigation/companyNavigation';
import React, { useEffect } from 'react';
import { HttpErrorType } from './common/error/helpers';
import { getOrElse, getOrUndefined } from 'util/resource';
import { ICompany, ProfileType } from 'types/company';
import { useCompanyFeatureToggles } from 'apis/CompanyAPI/companies/useCompanyFeatureToggles';
import { useDispatch } from 'react-redux';
import Resources from 'util/resource/Resources';
import { useCompanyAccess } from 'apis/CompanyAPI/companies/useCompanyAccess';
import { ActionTypes, fetched } from 'globalstate/company/details/actions';
import { useContextualNavigation } from 'domain/shared/Navigation';
import { CompanyOrCommunityLogo } from 'domain/shared/Navigation/Desktop/CompanyOrCommunityLogo';
import NavigationLogoMobile from 'domain/shared/Navigation/Mobile/NavigationLogoMobile';
import { hasScope } from 'util/scopeUtils';
import { useCompanyProfile } from 'apis/CompanyAPI/companies/useCompanyProfile';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import { SelfUserFetchedAction } from 'globalstate/reducers/intercom';
import { useMediaQuery, useTheme } from '@mui/material';
import ErrorPage from './common/error/ErrorPage';
import { CompanyNotFound } from './CompanyNotFound';
import { useUnreadBoardroomDocuments } from 'apis/DocumentAPI/useBoardroomDocuments';
import { emptyPaginatedResult } from 'util/paginationUtils';
import { useCompanyRooms } from 'apis/CompanyAPI/rooms/useRooms';
import { useMentorMentorships } from 'apis/MentorAPI/useMentorship';
import { useCompanyInbox } from './Inbox/useCompanyInbox';
import { companyUrls } from 'urls';

type RouteContextType = { value: ProfileType };

const routeContext: RouteContextType = {
  value: 'community',
};

export const RouteContext = React.createContext<RouteContextType>(routeContext);

interface Props {
  slug: string;
  children: (company: ICompany) => React.ReactElement;
}

export default function CompanyContainer({ slug, children }: Props): JSX.Element {
  const { resource: userProfileResource } = useSelfUserProfile();
  const { resource: companyProfileResource } = useCompanyProfile(slug);
  const { resource: accessResource } = useCompanyAccess(slug);
  const rooms = useCompanyRooms(slug);

  const dispatch = useDispatch();
  const scopes = getOrUndefined(accessResource)?.scopes ?? [];

  const companyProfile = getOrUndefined(companyProfileResource);

  const companyFeatures = useCompanyFeatureToggles(slug, hasScope({ action: 'Read', resource: 'Company' }, scopes));
  const canReadBoardroomDocuments = hasScope({ action: 'Read', resource: 'Boardroom' }, scopes);
  const unreadBoardroomsDocumentsResource = useUnreadBoardroomDocuments(companyProfile?.id, canReadBoardroomDocuments);
  const unreadBoardroomDocuments = getOrElse(unreadBoardroomsDocumentsResource.resource, emptyPaginatedResult());

  const mentorMentorshipsResource = useMentorMentorships(slug);

  // Just for the sake of intercom reducer 🥲
  useEffect(() => {
    if (userProfileResource.state === 'fetched') {
      dispatch<SelfUserFetchedAction>({
        type: 'self user fetched',
        payload: userProfileResource.resource,
      });
    }
  }, [userProfileResource.state]);

  useEffect(() => {
    const access = getOrUndefined(accessResource);

    // Just for the sake of intercom reducer 🥲
    if (companyProfile && access) {
      dispatch<fetched>({
        type: ActionTypes.FETCHED,
        payload: {
          company: {
            id: companyProfile.id,
            name: companyProfile.name,
            isCommunity: false,
          },
          access: { scopes: access.scopes, roles: access.roles },
        },
      });
    }
  }, [companyProfileResource.state, accessResource.state]);

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const canReadCompanyInbox = hasScope({ action: 'Read', resource: 'Company.Inbox' }, scopes);
  const { resource: companyConversationsResource } = useCompanyInbox(
    companyProfile?.id ?? 0,
    { page: 1, limit: 20 },
    canReadCompanyInbox,
  );
  const unreadMessagesAsCompany =
    getOrUndefined(companyConversationsResource)?.values.filter(c => c.hasUnreadMessages)?.length ?? 0;

  const navLinks = companyProfile
    ? companyNavigation(
        companyProfile,
        scopes,
        unreadBoardroomDocuments.total,
        unreadMessagesAsCompany,
        mentorMentorshipsResource.resource,
        getOrElse(rooms.resource, { values: [] })?.values,
        getOrElse(companyFeatures.resource, []),
        isMdUp,
      )
    : [];

  const company = getOrUndefined(companyProfileResource);
  const access = getOrUndefined(accessResource);
  const navLogo =
    company && access ? (
      <CompanyOrCommunityLogo
        companyOrCommunity={company}
        canEditCompany={hasScope({ action: 'Write', resource: 'Company' }, access.scopes)}
        routeContext="company"
      />
    ) : undefined;

  const navLogoMobile =
    company && access
      ? (onClick: (url: string) => void) => (
          <NavigationLogoMobile
            onClick={() => onClick(companyUrls.overview(company.slug, 'profile'))}
            name={company.name}
            logoURL={company.logoURL}
            type="company"
          />
        )
      : undefined;

  useContextualNavigation({ navLinks, navLogo, navLogoMobile }, [
    slug,
    companyProfileResource.state,
    company?.logoURL,
    accessResource.state,
    mentorMentorshipsResource.resource.state,
    rooms.resource.state,
    unreadBoardroomsDocumentsResource.resource.state,
    unreadMessagesAsCompany,
  ]);

  return (
    <RouteContext.Provider value={{ value: 'company' }}>
      <CompanyErrorPage
        errorType={companyProfileResource.state === 'error' ? companyProfileResource.errorType : undefined}
      >
        <Resources renderLoading="Bar" resources={[companyProfileResource, accessResource]}>
          {([pitch]) => children(pitch)}
        </Resources>
      </CompanyErrorPage>
    </RouteContext.Provider>
  );
}

export function CompanyErrorPage({
  children,
  errorType,
}: {
  errorType: HttpErrorType | undefined;
  children: React.ReactElement;
}): JSX.Element {
  switch (errorType) {
    case 'NotFound':
    case 'Unauthorized':
      return <CompanyNotFound />;
    case 'Error':
      return <ErrorPage />;
    default:
      return children;
  }
}
