export interface PaginationOptions {
  limit?: number;
  page?: number;
}

export interface PaginatedResult<T> {
  values: T[];
  limit: number;
  page: number;
  pages: number;
  pageSize: number;
  total: number;
}

export interface List<T> {
  values: T[];
}

export function emptyList<T>(): List<T> {
  return { values: [] };
}

export type SortOrder = 'asc' | 'desc';
export interface SortOptions {
  sortBy: string;
  sortOrder: SortOrder;
}

export type SortByOptions = 'magic' | 'alphabetically' | 'random';
