import React from 'react';
import SvgIcon, { IconProps } from './SvgIcon';

export default function TieIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12.295 6.639 9.933 17.724c-.188.882-.084 1.213.564 1.846l2.62 2.562c.345.338.518.507.733.507.214 0 .387-.17.732-.507l2.62-2.562c.648-.633.752-.964.564-1.846L15.404 6.64M10.906 3.763c-.067-.524-.1-.786.053-.955.154-.17.425-.17.969-.17h3.843c.544 0 .816 0 .97.17.153.169.12.43.052.955l-.051.395c-.143 1.108-.214 1.662-.57 2.021-.058.059-.12.112-.187.16-.412.3-.986.3-2.135.3-1.15 0-1.724 0-2.135-.3a1.534 1.534 0 0 1-.188-.16c-.356-.36-.427-.913-.57-2.021l-.05-.395Z" />
      <path d="M10.35 15.639c-.608.692-1.123 1-1.5 1-.485 0-1.357-1.22-1.744-2.105-.181-.415-.272-.622-.254-.885.018-.262.137-.454.375-.838 1.345-2.17 3.637-4.151 5.123-6.172" />
    </SvgIcon>
  );
}
