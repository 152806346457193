import { useMediaQuery } from '@mui/material';
import { mentorExpertise } from 'apis/MentorAPI/types';
import React from 'react';
import { DetailedUserProfile } from 'types/user';
import SkillChip from 'ui/domain/Chips/SkillChip';
import ChipList from 'ui/elements/Chip/ChipList';
import SectionHeading from 'ui/elements/SectionHeading';
import { bluePlanetTheme } from 'ui/theme';
import Card from 'ui/views/cards/Card';
import TruncatedList from 'util/components/TruncatedList';

export default function MentorProfile({ user }: { user: DetailedUserProfile }) {
  const isSmDown = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const chipsLimit = isSmDown ? 4 : Infinity;
  if (!user.isMentor || user.mentorExpertise.length === 0) {
    return null;
  }
  return (
    <Card className="u-content-spacing-top">
      <SectionHeading heading="Mentor profile"></SectionHeading>
      <h6 className="text-metadata u-half-spacing-bottom">Mentor Expertise</h6>
      <ChipList>
        <TruncatedList
          values={user.mentorExpertise.map(x => ({
            name: x,
            label: mentorExpertise[x],
          }))}
          limit={chipsLimit}
          renderItem={label => <SkillChip label={label.label} />}
          renderExpandButton={(label, onClick) => <SkillChip label={label.label} onClick={onClick} />}
        />
      </ChipList>
    </Card>
  );
}
