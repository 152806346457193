import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import Button from 'ui/elements/buttons/Button';
import { SelfUserProfile, PatchUserPayload } from 'types/user';
import CurrencyRangeInput from 'ui/elements/form/numbers/CurrencyRangeInput';
import { DEFAULT_CURRENCY, getCurrencySymbol } from 'util/currency';
import { formatThousands } from 'util/numbers';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import { saveUser } from 'actions/userProfiles';
import ButtonList from 'ui/elements/buttons/ButtonList';
import SelectCloud from 'ui/elements/form/SelectCloud/SelectCloud';
import Label from 'ui/elements/form/Label';
import CurrencyInput from 'ui/elements/form/numbers/CurrencyInput';
import MaxWidth from 'ui/views/containers/MaxWidth';
import { USER_WIZARD_CONTENT_MIN_HEIGHT } from '../UserOnboardingWizard';

interface OwnProps {
  onComplete: () => void;
  onStart?: () => void;
  onBack?: () => void;
  closeWizard?: () => void;
  userProfile: SelfUserProfile;
}

export const suggestedInvestmentRanges = [[1000, 5000], [5000, 25000], [25000, 100000], [100000, 500000], [500000]];
export const suggestedInvestmentCapacities = [5000, 25000, 100000, 500000];
const preferNotToDisclose = { name: 'Prefer not to disclose', value: -1 };

export default function InvestmentRangeForm({ onStart, closeWizard, onComplete, onBack, userProfile }: OwnProps) {
  const notify = useNotify();
  const [updateUser, isSaving] = useLazyResource((user: PatchUserPayload) => saveUser(user), {
    onSuccess: () => {
      onComplete();
    },
    onFailure: () => notify('error', `Unable to save changes`),
  });

  useEffect(() => {
    onStart && onStart();
  }, [onStart]);

  const mapRange = (currency: string, from: number, to?: number) => ({
    name: `${getCurrencySymbol(currency)} ${formatThousands(from)} ${to ? '- ' + formatThousands(to) : '+'}`,
    from,
    to,
  });

  const mapInvestmentCapacity = (currency: string, value: number) =>
    value === -1
      ? preferNotToDisclose
      : {
          name: `${getCurrencySymbol(currency)} ${formatThousands(value)}`,
          value,
        };

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        preferredInvestmentSizeFrom: userProfile.preferredInvestmentSize?.rangeFrom,
        preferredInvestmentSizeTo: userProfile.preferredInvestmentSize?.rangeTo,
        preferredInvestmentSizeCurrency: userProfile.preferredInvestmentSize?.currency ?? DEFAULT_CURRENCY,
        investmentCapacity: userProfile.investmentCapacity?.value,
        investmentCapacityCurrency: userProfile.investmentCapacity?.currency ?? DEFAULT_CURRENCY,
      }}
      onSubmit={async values => {
        updateUser({
          preferredInvestmentSize: values.preferredInvestmentSizeFrom
            ? {
                rangeFrom: values.preferredInvestmentSizeFrom,
                rangeTo: values.preferredInvestmentSizeTo ? values.preferredInvestmentSizeTo : undefined,
                currency: values.preferredInvestmentSizeCurrency,
              }
            : undefined,
          investmentCapacity:
            // To make the "prefer not to disclose button" work we use -1 as a value for that one
            values.investmentCapacity && values.investmentCapacity !== -1
              ? { value: values.investmentCapacity, currency: values.investmentCapacityCurrency }
              : undefined,
        });
      }}
    >
      {form => (
        <>
          <Content style={{ minHeight: USER_WIZARD_CONTENT_MIN_HEIGHT }}>
            <Form>
              <Label>Preferred investment range</Label>
              <SelectCloud
                options={suggestedInvestmentRanges.map(([from, to]) =>
                  mapRange(form.values.preferredInvestmentSizeCurrency, from, to),
                )}
                values={
                  form.values.preferredInvestmentSizeFrom
                    ? [
                        mapRange(
                          form.values.preferredInvestmentSizeCurrency,
                          form.values.preferredInvestmentSizeFrom,
                          form.values.preferredInvestmentSizeTo,
                        ),
                      ]
                    : []
                }
                onSelect={value => {
                  form.setFieldValue('preferredInvestmentSizeFrom', value.from.toString());
                  form.setFieldValue('preferredInvestmentSizeTo', value.to ? value.to.toString() : '');
                }}
                onRemove={() => {
                  form.setFieldValue('preferredInvestmentSizeFrom', '');
                  form.setFieldValue('preferredInvestmentSizeTo', '');
                }}
                customInputComponent={
                  <MaxWidth width="xs" className="u-half-spacing-top">
                    <CurrencyRangeInput
                      from={{
                        onChange: v => form.setFieldValue('preferredInvestmentSizeFrom', v),
                        placeholder: '10 000',
                        name: 'preferredInvestmentSizeFrom',
                        value: form.values.preferredInvestmentSizeFrom,
                        error: form.errors.preferredInvestmentSizeFrom,
                        touched: form.touched.preferredInvestmentSizeFrom,
                      }}
                      to={{
                        onChange: v => form.setFieldValue('preferredInvestmentSizeTo', v),
                        placeholder: '500 000',
                        name: 'preferredInvestmentSizeTo',
                        value: form.values.preferredInvestmentSizeTo,
                        error: form.errors.preferredInvestmentSizeTo,
                        touched: form.touched.preferredInvestmentSizeTo,
                      }}
                      currency={form.values.preferredInvestmentSizeCurrency}
                      onCurrencyChange={v => form.setFieldValue('preferredInvestmentSizeCurrency', v)}
                    />
                  </MaxWidth>
                }
              />

              <div className="u-section-spacing-y">
                <Label>Investment capacity next 24 months</Label>
                <SelectCloud
                  options={[
                    ...suggestedInvestmentCapacities.map(value =>
                      mapInvestmentCapacity(form.values.investmentCapacityCurrency, value),
                    ),
                    preferNotToDisclose,
                  ]}
                  values={
                    form.values.investmentCapacity
                      ? [mapInvestmentCapacity(form.values.investmentCapacityCurrency, form.values.investmentCapacity)]
                      : []
                  }
                  onSelect={value => {
                    form.setFieldValue('investmentCapacity', value.value);
                  }}
                  onRemove={() => {
                    form.setFieldValue('investmentCapacity', '');
                  }}
                  customInputComponent={
                    <MaxWidth className="u-half-spacing-top" width="xs">
                      <CurrencyInput
                        onChange={v => form.setFieldValue('investmentCapacity', v && v !== -1 ? v.toString() : '')}
                        placeholder={'10 000'}
                        name={'investmentCapacity'}
                        value={form.values.investmentCapacity === -1 ? '' : form.values.investmentCapacity}
                        error={form.errors.investmentCapacity}
                        touched={form.touched.investmentCapacity}
                        currency={form.values.investmentCapacityCurrency}
                        onCurrencyChange={v => form.setFieldValue('investmentCapacityCurrency', v)}
                      />
                    </MaxWidth>
                  }
                />
              </div>
            </Form>
          </Content>
          <DialogActions align="space-between">
            <ButtonList>
              <Button
                kind="primary"
                onClick={() => form.handleSubmit()}
                isLoading={isSaving}
                className="track-investor-onboarding-preference-continue track-wizard-step-continue"
              >
                {closeWizard ? 'Next' : 'Update'}
              </Button>
              {onBack && (
                <Button kind="primary" onClick={onBack} color="greyLight">
                  Back
                </Button>
              )}
            </ButtonList>
            {closeWizard && (
              <Button onClick={closeWizard} kind="tertiary" color="grey">
                Pick it up later
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Formik>
  );
}
