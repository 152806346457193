import React, { useEffect } from 'react';

import FormRow from 'ui/elements/form/FormRow';
import Button from 'ui/elements/buttons/Button';
import { SelfUserProfile, PatchUserPayload } from 'types/user';
import { LinkedInField } from 'domain/users/UserProfile/LinkedInField';
import LocationInput from 'domain/shared/Location/LocationInput';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import useLazyResource from 'util/resource/useLazyResource';
import { saveUser } from 'actions/userProfiles';
import useNotify from 'hooks/useNotify';
import InlineEditableField from 'ui/elements/form/InlineEditableField';
import TextField from 'ui/elements/form/TextField';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { formatShortLocation } from 'util/locationUtils';
import i18n from 'util/i18n';
import LinkedIn from 'ui/domain/Contact/LinkedIn';
import Phone from 'ui/domain/Contact/Phone';
import { useMediaQuery, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { CloudinaryUploadResponse } from 'types';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';
import { invalidate } from 'hooks/useSWR';
import { selfDetailedKey } from 'apis/CompanyAPI/users/useSelfUserProfile';
import { ContentAPI } from 'apis/ContentAPI';
import config from 'config';
import ImageUpload from 'ui/modules/ImageUpload';
import { PROFILE_IMAGE_UPLOAD_LIMIT } from 'util/constants';
import { resize } from 'util/cloudinary';
import { staticFileLocations } from 'urls';
import { getOrUndefined } from 'util/resource';
import { OnboardingWizardStatus, onboardingApiUrls } from 'apis/OnboardingAPI';
import useResource from 'util/resource/useResource';

interface OwnProps {
  onComplete: () => void;
  onStart?: () => void;
  onBack?: () => void;
  userProfile: SelfUserProfile;
  context: 'onboarding' | 'profile';
}

const ValueContainer = styled.div`
  background-color: transparent;
  cursor: pointer;

  ${bluePlanetTheme.breakpoints.down('sm')} {
    background-color: ${bluePlanetTheme.bluePlanetPalette.grey.light};
    min-height: 44px;
    border-radius: ${bluePlanetTheme.shape.borderRadius}px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export default function UserInfoForm({ onComplete, onStart, onBack, userProfile: user, context }: OwnProps) {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const { resource: onboardingWizardStatusResource } = useResource<OnboardingWizardStatus>(onboardingApiUrls.getStatus);

  const companyUserIsOnboardedIn = getOrUndefined(onboardingWizardStatusResource)?.company;

  const notify = useNotify();
  const [updateUser, isSaving] = useLazyResource((user: PatchUserPayload) => saveUser(user), {
    onFailure: () => notify('error', 'Unable to save changes'),
  });

  useEffect(() => {
    onStart && onStart();
  }, [onStart]);

  // When a user registers a new company this will set the user.employer field to
  // the company name. A nice little nicety for our users.
  useEffect(() => {
    if (user.employer === null && companyUserIsOnboardedIn) {
      updateUser({ employer: companyUserIsOnboardedIn.name });
    }
  }, [user.employer, companyUserIsOnboardedIn]);

  const [handleImageUploadSuccess] = useLazyResource(
    (image: CloudinaryUploadResponse) => usersApi.userProfiles.updateProfileImage(image.secure_url),
    {
      onSuccess: (_, image) => {
        const updatedValue = { ...user, imageUrl: `${image.secure_url}` };
        invalidate(selfDetailedKey, updatedValue);
      },
      onFailure: () => notify('error', 'Could not change profile image'),
    },
  );

  return (
    <>
      <Content>
        <div className="u-flex-align-center u-flex--column">
          <FormRow>
            <ImageUpload
              onUploadSuccess={handleImageUploadSuccess}
              onUploadFailure={() => notify('error', 'Could not change profile image')}
              getUploadUrl={() =>
                ContentAPI(config.CONTENT_API_URL).images.getUserUploadUrl(user.id, user.id.toString())
              }
              aspectRatio={1}
              maxFileSize={PROFILE_IMAGE_UPLOAD_LIMIT}
              imageUrl={resize(user.imageUrl || staticFileLocations.defaultProfileImage, { width: 600, height: 600 })}
              width={135}
              shape="round"
            />
          </FormRow>
          <FormRow>
            <h3 className="text-h3">{user.name}</h3>
          </FormRow>
          <FormRow
            className="u-flex-align-center u-flex--gap-half"
            style={{
              ...(!isSmUp && { display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%' }),
            }}
          >
            <InlineEditableField
              defaultValue={user.position}
              onConfirm={position => {
                updateUser({ position });
              }}
              buttonStyle={{ width: isSmUp ? 150 : '100%', height: isSmUp ? undefined : 44 }}
              renderInputComponent={onChange => (
                <TextField
                  autoFocus
                  name="position"
                  type="string"
                  onChange={e => onChange(e.target.value)}
                  placeholder="CEO"
                  defaultValue={user.position}
                  style={{ width: '100%', minWidth: 200 }}
                />
              )}
              valueComponent={<ValueContainer>{user.position}</ValueContainer>}
              label="Add title/role"
            />
            {isSmUp && <span>at</span>}
            <InlineEditableField
              defaultValue={user.employer}
              onConfirm={employer => {
                updateUser({ employer });
              }}
              buttonStyle={{ width: isSmUp ? 150 : '100%', height: isSmUp ? undefined : 44 }}
              renderInputComponent={onChange => (
                <TextField
                  autoFocus
                  name="employer"
                  onChange={e => onChange(e.target.value)}
                  placeholder="Company name"
                  defaultValue={user.employer}
                  style={{ width: '100%', minWidth: 200 }}
                />
              )}
              valueComponent={<ValueContainer>{user.employer}</ValueContainer>}
              label="Add employer"
            />
          </FormRow>
          <FormRow
            className="u-flex"
            style={{ ...(!isSmUp && { width: '100%', alignItems: 'stretch', flexDirection: 'column' }) }}
          >
            <InlineEditableField
              defaultValue={user.location}
              onConfirm={location => {
                updateUser({ location });
              }}
              buttonStyle={{ width: '100%', minWidth: 200, height: isSmUp ? undefined : 44 }}
              renderInputComponent={onChange => (
                <LocationInput
                  style={{ width: '100%', minWidth: 200 }}
                  autoFocus
                  name="location"
                  searchArea="places"
                  value={user.location}
                  placeholder="Search for location"
                  autoComplete="address-level2"
                  onChange={location => onChange(location || undefined)}
                />
              )}
              valueComponent={user.location && <ValueContainer>{formatShortLocation(user.location)}</ValueContainer>}
              label="Add your location"
            />
          </FormRow>
          <FormRow
            className="u-flex-align-center u-flex--gap-half u-content-spacing-top"
            style={{ ...(!isSmUp && { width: '100%', alignItems: 'stretch', flexDirection: 'column' }) }}
          >
            <InlineEditableField
              defaultValue={user.linkedInUrl}
              onConfirm={linkedInUrl => {
                updateUser({ linkedInUrl });
              }}
              buttonStyle={{ minWidth: 200, width: '100%', height: isSmUp ? undefined : 44 }}
              renderInputComponent={onChange => (
                <LinkedInField
                  autoFocus
                  type="uri"
                  defaultValue={user.linkedInUrl}
                  onChange={onChange}
                  placeholder="jane-doe-162"
                  style={{ minWidth: 200, width: '100%' }}
                />
              )}
              valueComponent={
                user.linkedInUrl && (
                  <ValueContainer>
                    <LinkedIn linkedInUrl={user.linkedInUrl} linkIsDisabled />
                  </ValueContainer>
                )
              }
              label="Add LinkedIn URL"
            />
            <InlineEditableField
              defaultValue={user.phone}
              onConfirm={phone => {
                updateUser({ phone });
              }}
              buttonStyle={{ minWidth: 200, width: '100%', height: isSmUp ? undefined : 44 }}
              renderInputComponent={onChange => (
                <TextField
                  autoFocus
                  defaultValue={user.phone}
                  onChange={e => onChange(e.target.value)}
                  placeholder={i18n('en').placeholders.phone}
                  autoComplete="tel"
                  style={{ minWidth: 120, width: '100%' }}
                />
              )}
              valueComponent={
                user.phone && (
                  <ValueContainer>
                    <Phone className="u-half-spacing-left" phone={user.phone} linkIsDisabled />
                  </ValueContainer>
                )
              }
              label="Add phone number"
            />
          </FormRow>
        </div>
      </Content>
      <DialogActions>
        <ButtonList>
          <Button kind="primary" onClick={() => onComplete()} disabled={isSaving}>
            {context === 'onboarding' ? 'Finish' : 'Continue'}
          </Button>
          {onBack && (
            <Button kind="primary" onClick={onBack} color="greyLight">
              Back
            </Button>
          )}
        </ButtonList>
      </DialogActions>
    </>
  );
}
