import React from 'react';
import { Link } from 'react-router-dom';
import { DetailedUserProfile } from 'types/user';
import Avatar from 'ui/elements/avatars/Avatar';
import { userUrls } from 'urls';
import KeyUserInfo from './KeyUserInfo';
import Button from 'ui/elements/buttons/Button';
import EditIcon from 'ui/elements/icons/EditIcon';
import { useMediaQuery, useTheme } from '@mui/material';
import MentorProfile from './MentorProfile';
import InvestorProfile from './InvestorProfile';

export default function UserProfile({ user, showEdit }: { user: DetailedUserProfile; showEdit: boolean }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {showEdit && (
        <Link to={userUrls.profile.edit} className="u-flex-end">
          <Button kind="secondary">
            <EditIcon className="u-quarter-spacing-right"></EditIcon>Edit profile
          </Button>
        </Link>
      )}
      <div className="u-flex-align-center u-flex--column">
        <Avatar
          size={isMobile ? 144 : 200}
          resize={isMobile ? 288 : 400}
          imageUrl={user.imageUrl}
          userName={user.name}
          style={{
            display: 'block',
            position: 'relative',
            top: isMobile ? '77px' : '100px',
            zIndex: 1,
            marginTop: isMobile ? '-60px' : '-65px', // remove dead space above user avatar
          }}
        />
      </div>
      <KeyUserInfo className="u-content-spacing-bottom" user={user} />
      {user.isInvestor && <InvestorProfile user={user} />}
      <MentorProfile user={user} />
    </>
  );
}
