import React from 'react';
import { CommunityUpdateVisibility } from 'types/content';
import Button from 'ui/elements/buttons/Button';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { fontSizeSmall } from 'ui/theme/themeBluePlanet';
import ChevronDownIcon from 'ui/elements/icons/ChevronDownIcon';
import { Title, Content, DialogActions } from 'ui/views/dialogs/Dialog';
import { FormControl, FormControlLabel, Hidden, List, RadioGroup, useMediaQuery } from '@mui/material';
import Radio from 'ui/elements/form/choice/Radio';
import { CommunityConfiguration, TinyCommunity } from 'types/company/community';

const visibilityDisplayString = (visibility: CommunityUpdateVisibility) => {
  switch (visibility) {
    case 'CommunityMembers':
      return 'Everyone in the community';
    case 'Founders':
      return 'Founders';
    case 'CommunityMembersExceptFounders':
      return 'Investors, mentors & advisors';
  }
};

const PostTo = styled.span`
  font-weight: ${bluePlanetTheme.typography.fontWeightMedium};
  font-size: ${fontSizeSmall};
  color: ${bluePlanetTheme.bluePlanetPalette.grey.main};
  text-align: left;
`;

export function CommunityVisibilitySelector({
  setIsVisibilitySelectorOpen,
  visibility,
  setVisibility,
  community,
  isPostingAsCommunity,
  communityConfiguration,
}: {
  setIsVisibilitySelectorOpen: (value: boolean) => void;
  visibility: CommunityUpdateVisibility;
  setVisibility: (value: CommunityUpdateVisibility) => void;
  community: TinyCommunity;
  communityConfiguration: CommunityConfiguration;
  isPostingAsCommunity: boolean;
}) {
  return (
    <>
      <Title onClose={() => setIsVisibilitySelectorOpen(false)}>Who are you posting to?</Title>
      <Content>
        <List>
          <FormControl>
            <RadioGroup value={visibility} onChange={e => setVisibility(e.target.value as CommunityUpdateVisibility)}>
              <FormControlLabel
                className="u-half-spacing-bottom"
                style={{ alignItems: 'flex-start' }}
                control={<Radio />}
                value="CommunityMembers"
                label={
                  <div>
                    <div className="u-flex u-flex--align-items-center" style={{ gap: '0.4rem' }}>
                      Community members
                    </div>
                    <div style={{ fontWeight: 'normal' }}>Everyone in {community.name}</div>
                  </div>
                }
              />
              {isPostingAsCommunity && communityConfiguration.companyMembersIsEnabled && (
                <FormControlLabel
                  className="u-half-spacing-bottom"
                  control={<Radio />}
                  value="Founders"
                  label={'Founders'}
                />
              )}
              {isPostingAsCommunity && communityConfiguration.companyApplicationIsEnabled && (
                <FormControlLabel
                  className="u-half-spacing-bottom"
                  control={<Radio />}
                  value="CommunityMembersExceptFounders"
                  label=" Investors, mentors & advisors"
                />
              )}
            </RadioGroup>
          </FormControl>
        </List>
      </Content>
      <DialogActions align="space-between">
        <Button kind="primary" onClick={() => setIsVisibilitySelectorOpen(false)}>
          Done
        </Button>
      </DialogActions>
    </>
  );
}

export default function Visibility({
  openVisibilityPicker,
  visibility,
}: {
  openVisibilityPicker: () => void;
  visibility: CommunityUpdateVisibility;
}) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  return (
    <>
      <Button kind="primary" color="greyLight" onClick={openVisibilityPicker}>
        <div
          className="u-flex u-flex-align-center u-flex-space-between"
          style={{ minWidth: isMobile ? undefined : '200px' }}
        >
          <div className="u-flex u-flex-align-center">
            <Hidden smDown>
              <EyeIcon className="u-half-spacing-right" />
            </Hidden>
            <div className="u-flex u-flex--column text-align-left">
              <PostTo>Post to:</PostTo>
              <span>{visibilityDisplayString(visibility)}</span>
            </div>
          </div>
          <ChevronDownIcon className="u-half-spacing-left" />
        </div>
      </Button>
    </>
  );
}
