import config from 'config';
import { Auth0User } from 'types';

const namespace = config.AUTH0_NAMESPACE;

export const createRedirectUri = (relativePath: string) => {
  return `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}${relativePath}`;
};

export const currentPath = () =>
  `${location.protocol}//${location.hostname}${
    location.port ? `:${location.port}` : ''
  }${`${window.location.pathname.toLowerCase()}${window.location.search}`}`;

export const currentRelativePath = () => `${window.location.pathname.toLowerCase()}${window.location.search}`;

export const extractUserId = (user?: Auth0User) => (user ? parseInt(user[`${namespace}crowd_id`], 10) : undefined);
export const getAdminRights = (user?: Auth0User): AdminRights[] =>
  ((user ? user[`${namespace}admin_rights`] : '') || '').split('|') as AdminRights[];

export type AdminRights = 'backoffice' | 'write-everything' | 'read-everything';

export const LOGIN_METHOD_AUTH0 = 'Auth0';
export function getLoginMethod(user?: Auth0User) {
  if (!user) return undefined;
  if (user.sub?.startsWith('auth0')) return LOGIN_METHOD_AUTH0;
  if (user.sub?.startsWith('google')) return 'Google';
  if (user.sub?.startsWith('linkedin')) return 'LinkedIn';
  if (user.sub !== undefined) return user.sub.split('|')[0];
  return undefined;
}
