import { useMediaQuery } from '@mui/material';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';
import CenteredModalDialog from 'ui/views/dialogs/CenteredModalDialog';
import MuiFullscreenDialog, { Content } from 'ui/views/dialogs/FullscreenDialog';
import IconButton from 'ui/elements/icons/IconButton';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import styles from './styles.scss';
import cx from 'classnames';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import PitchDeckPDF from './PitchDeckPDF';

interface Props {
  pageNumber: number;
  numPages: number;
  onClose: () => void;
}

export default function PitchDeckPDFDialog({ pageNumber, numPages, onClose }: Props) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const containerRef = React.useRef<HTMLDivElement>(null);

  const closeDocument = () => {
    onClose();
  };

  return isMobile ? (
    <MuiFullscreenDialog open onClose={closeDocument}>
      <div className={styles.mobileCloseButtonWrapper}>
        <IconButton
          className={cx('u-half-spacing', styles.mobileCloseButton)}
          color="indigo"
          onClick={closeDocument}
          aria-label="Close"
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Content padding="none">
        <div ref={containerRef}>
          <PitchDeckPDF pageNumber={pageNumber} numPages={numPages} />
        </div>
      </Content>
    </MuiFullscreenDialog>
  ) : (
    <CenteredModalDialog
      open
      onClose={closeDocument}
      disableEnforceFocus
      overflowY="scroll" // always show scrollbar to prevent window from jumping
      width="wide"
    >
      <PitchDeckPDF pageNumber={pageNumber} numPages={numPages} />
    </CenteredModalDialog>
  );
}
