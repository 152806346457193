import React from 'react';
import { DetailedUserProfile } from 'types/user';
import bluePlanetTheme, { BackdropColor } from 'ui/theme/themeBluePlanet';
import CenteredModalDialog from 'ui/views/dialogs/CenteredModalDialog';
import LoadableResources from 'util/resource/Resources';
import UserProfile from '.';
import { BackofficeUserDetails } from 'pages/Backoffice/shared/BackofficeUserDetails';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import Dialog, { Content, Title } from 'ui/views/dialogs/Dialog';
import useResource from 'util/resource/useResource';
import { userAPIUrls } from 'apis/CompanyAPI/users/usersApi';

interface UserProfilePreviewProps {
  userProfile: DetailedUserProfile;
  onClose: () => void;
  backdropColor?: BackdropColor;
}

interface UserProfileDialogProps {
  cwUserId: UUID | undefined;
  onClose: () => void;
  isOpen?: boolean;
}

export function UserProfilePreview(props: UserProfilePreviewProps) {
  const { userProfile, onClose } = props;
  return (
    <CenteredModalDialog open={true} onClose={onClose} backdropColor={props.backdropColor}>
      <div className="u-content-spacing-top" style={{ maxWidth: '65rem', marginLeft: 'auto', marginRight: 'auto' }}>
        <UserProfile user={userProfile} showEdit={false} />
      </div>
    </CenteredModalDialog>
  );
}

function UserProfileDialogContent(props: UserProfileDialogProps & { cwUserId: UUID }) {
  const { cwUserId, onClose } = props;
  const { resource: userResource } = useResource<DetailedUserProfile>(userAPIUrls.userProfiles.getDetailed(cwUserId));
  const { resource: meResource } = useSelfUserProfile();

  return (
    <>
      <Title paddingY="none" paddingX="half" sx={{}} onClose={onClose} backgroundColor="grey-light" />
      <Content sx={{ background: bluePlanetTheme.bluePlanetPalette.grey.light }} paddingY="bottom" paddingX="default">
        <LoadableResources resources={[userResource, meResource]}>
          {([userProfile, me]) => (
            <div style={{ maxWidth: '65rem', marginLeft: 'auto', marginRight: 'auto' }}>
              <UserProfile user={userProfile} showEdit={userProfile.cwUserId === me.cwUserId} />
              <BackofficeUserDetails userId={userProfile.id} />
            </div>
          )}
        </LoadableResources>
      </Content>
    </>
  );
}

export default function UserProfileDialog(props: UserProfileDialogProps) {
  return (
    <Dialog maxWidth="lg" open={props.isOpen ?? true} onClose={props.onClose} scroll="paper" mobileLayout="fullscreen">
      {(props.isOpen ?? true) && props.cwUserId && <UserProfileDialogContent {...props} cwUserId={props.cwUserId} />}
    </Dialog>
  );
}
