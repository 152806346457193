import { Author, AuthorProfile } from 'domain/shared/author';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthorLink } from '.';
import { Hidden } from '@mui/material';

export function PostSubtext(props: { user?: AuthorProfile; context: Author['type'] }) {
  const [userDialogIsOpen, setUserDialogIsOpen] = useState(false);
  const handleUserClick = () => setUserDialogIsOpen(true);

  if (!props.user) {
    return null;
  }

  return props.context !== 'user' ? (
    <div>
      By <AuthorLink name={props.user.name} onClick={handleUserClick} />
      <span className="u-quarter-spacing-left">&#x2022;</span>
      {props.user.cwUserId && (
        <UserProfileDialog
          isOpen={userDialogIsOpen}
          cwUserId={props.user.cwUserId}
          onClose={() => setUserDialogIsOpen(false)}
        />
      )}
    </div>
  ) : props.user.position ? (
    <>
      <span>{props.user.position}</span>
      <Hidden smDown>
        <span className="u-quarter-spacing-x">-</span>
      </Hidden>
    </>
  ) : null;
}

export function CompanyPostContext(props: { companyHref?: string; companyName?: string }) {
  return props.companyHref ? (
    <Link className="text-weight-medium" to={props.companyHref || ''}>
      {props.companyName}
    </Link>
  ) : (
    <span className="text-weight-medium">{props.companyName}</span>
  );
}

export function BoardRoomPostContext(props: { companyName?: string; companyHref?: string; roomHref?: string }) {
  return (
    <>
      <Link className="text-weight-medium" to={props.roomHref ?? '#'}>
        Boardroom
      </Link>{' '}
      of <CompanyPostContext companyHref={props.companyHref} companyName={props.companyName} />
    </>
  );
}

export function RoomPostContext(props: {
  companyName?: string;
  companyHref?: string;
  roomName?: string;
  roomHref?: string;
}) {
  return (
    <>
      <Link className="text-weight-medium" to={props.roomHref ?? '#'}>
        {props.roomName}
      </Link>{' '}
      for <CompanyPostContext companyHref={props.companyHref} companyName={props.companyName} />
    </>
  );
}
