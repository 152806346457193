import { Location, useMapboxLocation } from 'apis/MapboxAPI';
import useDebouncedValue from 'hooks/useDebouncedValue';
import React, { useState } from 'react';
import Label from 'ui/elements/form/Label';
import Select from 'ui/elements/form/Select/Select';

interface Props {
  name: string;
  placeholder?: string;
  label?: string;
  onChange: (value?: Location | null) => void;
  value?: Location;
  autoComplete?: string;
  autoFocus?: boolean;
  error?: string;
  className?: string;
  style?: React.CSSProperties;
  searchArea: 'places' | 'addresses' | 'countries';
}

export default function LocationInput({ value, name, label, onChange, error, className, searchArea, ...rest }: Props) {
  const [inputSearch, setInputSearch] = useState('');
  const debouncedSearch = useDebouncedValue(inputSearch, 300);
  const {
    data: options = [],
    error: optionsError,
    isFetching,
  } = useMapboxLocation({
    searchText: debouncedSearch,
    shouldSearch: value == null && inputSearch == debouncedSearch && debouncedSearch != '',
    maxResults: 40,
    searchArea,
  });
  return (
    <>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Select
        className={className}
        id={name}
        isClearable={true}
        name={name}
        value={value}
        noOptionsText={getNoOptionsText(
          inputSearch,
          isFetching,
          inputSearch.length > 0 && inputSearch !== debouncedSearch,
          optionsError,
        )}
        getOptionLabel={option => option.description}
        onInputChange={newInputValue => {
          const isNewSearch = value != null && newInputValue !== value.description;
          if (isNewSearch) {
            // If the value we have is not the same as a new input, we want to reset the value chosen,
            // as it means a new search has started, and the old value is not of interest anymore
            onChange(null);
          }
          setInputSearch(newInputValue);
        }}
        onChange={value => onChange(value || null)}
        options={options}
        error={!!error}
        helperText={error}
        {...rest}
      />
    </>
  );
}

function getNoOptionsText(search: string, isFetching: boolean, isWriting: boolean, error?: Error) {
  if (isFetching || isWriting) {
    return 'Searching for locations...';
  }

  if (error) {
    return 'Something went wrong while searching for location. Please try searching for something else or try again later.';
  }

  if (!search) {
    return 'Search for location';
  }

  return 'No locations found, please check your spelling or try searching for something else';
}
