import config from 'config';
import useSWR from 'hooks/useSWR';
import { CommunityRecentActivityList } from 'types/company/community';
import { useSWRResource } from 'util/resource/useSWRResource';

export const communityActivityKey = (communityId: number) =>
  `${config.CONTENT_API_URL}/communities/${communityId}/updates/recent`;

export function useCommunityActivity(communityId: number) {
  const response = useSWR<CommunityRecentActivityList>(communityActivityKey(communityId));
  return useSWRResource(response);
}
