import React, { useEffect } from 'react';
import Card from 'ui/views/cards/Card';
import styles from './styles.scss';
import cx from 'classnames';

import Resource from 'util/resource/Resource';
import EventAPI from 'apis/EventAPI';
import CalendarDate from 'ui/domain/Event/CalendarDate';
import { Link } from 'react-router-dom';
import { eventUrls } from 'urls';
import Logo from 'ui/domain/Logo';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';
import { asTime, dateWithDayName } from 'util/dateUtils';
import LocationIcon from 'ui/elements/icons/LocationIcon';
import { TinyOrganizationDTO } from 'types/organization';
import { halfSpacing } from 'ui/theme/themeConstants';
import useResource from 'util/resource/useResource';
import { EventDTO } from 'ui/domain/Event/types';

interface Props {
  eventId: UUID;
  className?: string;
  onContentLoaded?: () => void;
}

export default function ShareEventCard({ eventId, className, onContentLoaded }: Props) {
  const LOGO_SIZE = 32;
  const { resource } = useResource<EventDTO>(EventAPI.getPreview(eventId));

  useEffect(() => {
    if (resource.state === 'fetched' && onContentLoaded) onContentLoaded();
  }, [resource.state]);

  const OrganizationIcons = ({ organizations }: { organizations: TinyOrganizationDTO[] }) => {
    return (
      <>
        {organizations.map((org, idx) => {
          if (idx === organizations.length - 1) {
            return (
              <div
                key={`${org.id}-${idx}`}
                style={{ position: 'relative', left: `${idx * LOGO_SIZE * -0.75}px`, zIndex: 2 }}
              >
                <Logo className={styles.organizationIcon} size={32} url={org.logoURL} type="organization" />
              </div>
            );
          } else {
            return (
              <div
                key={`${org.id}-${idx}`}
                className={styles.moreOrganizationsIcon}
                style={{ left: `${idx * LOGO_SIZE * -0.75}px` }}
              ></div>
            );
          }
        })}
      </>
    );
  };

  return (
    <Card className={cx(styles.shareCard, className)} elevation={2} padding="none">
      <Link to={eventUrls.view(eventId)} target="_blank" rel="noopener noreferrer">
        <Resource resource={resource}>
          {event => (
            <>
              <div className="u-relative u-flex u-flex-align-center u-half-padding-x u-half-padding-y">
                {event.organizations && event.organizations.length > 0 && (
                  <div className="u-flex u-flex-align-center">
                    <OrganizationIcons organizations={event.organizations.map(o => o.organization)} />
                    <div
                      style={{
                        left: `${(event.organizations.length - 1) * LOGO_SIZE * -0.75}px`,
                      }}
                      className="u-relative u-flex u-flex-align-center"
                    >
                      <span
                        style={{ whiteSpace: 'nowrap' }}
                        className="text-body text-small u-half-spacing-left u-ellipsis"
                      >
                        {event.organizations[0].organization.name}
                      </span>
                      {event.organizations.length > 1 && (
                        <div className="text-metadata u-quarter-spacing-left">+{event.organizations.length - 1}</div>
                      )}
                    </div>
                  </div>
                )}
                <ChevronRightIcon
                  style={{ position: 'absolute', right: halfSpacing }}
                  color="dark-grey"
                  fontSize="small"
                  strokeWidth={2}
                />
              </div>
              <div className={styles.eventShareCardBackground}>
                <CalendarDate date={event.startsAt} background="indigo" />
                <div className={styles.eventTitle}>{event.title}</div>
              </div>
              <div className="u-half-padding">
                <div className="text-metadata">
                  {dateWithDayName(event.startsAt)} at {asTime(event.startsAt)}
                </div>
                <div>
                  <LocationIcon color="blue" fontSize="small" />
                  <span className={styles.eventLocation}>{event.location}</span>
                </div>
              </div>
            </>
          )}
        </Resource>
      </Link>
    </Card>
  );
}
