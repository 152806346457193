import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import useEmblaCarousel from 'embla-carousel-react';
import React, { useEffect, useState } from 'react';
import { bluePlanetTheme } from 'ui/theme';
import {
  contentSpacing,
  quarterSpacing,
  sectionSpacing,
  transitionHover,
  zLayer1,
  zLayer2,
} from 'ui/theme/themeConstants';
import ChevronLeftIcon from '../icons/ChevronLeftIcon';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import { EmblaOptionsType } from 'embla-carousel';
import { usePrevNextButtons } from './usePrevNextButtons';
import { Link } from 'react-router-dom';
import Card from 'ui/views/cards/Card';
import IconButton from '../icons/IconButton';
import { useDebouncedCallback } from 'use-debounce';

const SeeMoreContainer = styled.div`
  border-radius: 50%;
  border: 1px solid ${bluePlanetTheme.bluePlanetPalette.grey.main};
`;

export function SeeMoreCard({ href }: { href: string }) {
  return (
    <div className="u-half-spacing-left" style={{ display: 'flex', height: '100%' }}>
      <Link style={{ display: 'flex', flexGrow: 1 }} to={href}>
        <Card
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            flexDirection: 'column',
          }}
          hover="drop-shadow"
        >
          {/* Offset to match height of next/prev */}
          <SeeMoreContainer style={{ marginTop: '30px' }}>
            <IconButton color="grey">
              <ChevronRightIcon />
            </IconButton>
          </SeeMoreContainer>
          <div className="u-half-spacing-top">See more</div>
        </Card>
      </Link>
    </div>
  );
}

interface ResponsiveBreakpoints {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
}
const SlideContainer = styled.div(
  ({
    isNextEnabled,
    isPrevEnabled,
    fadeColor,
  }: {
    isPrevEnabled: boolean;
    isNextEnabled: boolean;
    fadeColor?: string;
  }) => `
  overflow: hidden;
  position: relative;

  --fade-gradient-width: ${contentSpacing};
    ${bluePlanetTheme.breakpoints.up('sm')} {
      --fade-gradient-width: ${sectionSpacing};
    }

    &:after, &:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      width: var(--fade-gradient-width);
      transition: opacity 0.3s ease;
    }

    &:after {
      opacity: ${isNextEnabled ? 1 : 0};
      background-image: linear-gradient(to right, transparent, ${fadeColor ?? bluePlanetTheme.bluePlanetPalette.grey.light});
      right: 0;
    }

    &:before {
      opacity: ${isPrevEnabled ? 1 : 0};
      background-image: linear-gradient(to left, transparent, ${fadeColor ?? bluePlanetTheme.bluePlanetPalette.grey.light});
      left: 0;
      z-index: ${zLayer1};
    }
`,
);

const Slides = styled.div(
  ({ slidesWidth, bleed }: { slidesWidth?: ResponsiveBreakpoints; bleed?: ResponsiveBreakpoints }) => `
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: ${slidesWidth?.xs || '80%'};
  transition: opacity 1s ease-in-out; 

  // margin-left and margin-right are used to offset the negative margin of the Bleed container
  margin-left: ${bleed?.xs ? `${bleed.xs}` : 'inherit'};
  margin-right: ${bleed?.xs ? `${bleed.xs}` : 'inherit'};

  ${bluePlanetTheme.breakpoints.up('sm')} {
    grid-auto-columns: ${slidesWidth?.sm || '40%'};
    margin-left: ${bleed?.sm ? `${bleed.sm}` : 'inherit'};
    margin-right: ${bleed?.sm ? `${bleed.sm}` : 'inherit'};

  }
  ${bluePlanetTheme.breakpoints.up('md')} {
    grid-auto-columns: ${slidesWidth?.md || '27%'};
    margin-left: ${bleed?.md ? `${bleed.md}` : 'inherit'};
    margin-right: ${bleed?.md ? `${bleed.md}` : 'inherit'};
  }
  ${bluePlanetTheme.breakpoints.up('lg')} {
    grid-auto-columns: ${slidesWidth?.lg || '20%'};
    margin-left: ${bleed?.lg ? `${bleed.lg}` : 'inherit'};
    margin-right: ${bleed?.lg ? `${bleed.lg}` : 'inherit'};
  }
  ${bluePlanetTheme.breakpoints.up('xl')} {
    grid-auto-columns: 20%;
  }
  gap: 1.25rem;
`,
);

// Bleed is used to create a negative margin on the left and right of the carousel,
// so that the items dissapear off the edge of the screen.
// the negative margin is offset by a positive margin on the Slides container.
const Bleed = styled.div(
  ({ amount }: { amount?: ResponsiveBreakpoints }) => `
    margin-left: ${amount?.xs ? `-${amount.xs}` : 'inherit'};
    margin-right: ${amount?.xs ? `-${amount.xs}` : 'inherit'};

  ${bluePlanetTheme.breakpoints.up('sm')} {
    margin-left: ${amount?.sm ? `-${amount.sm}` : 'inherit'};
    margin-right: ${amount?.sm ? `-${amount.sm}` : 'inherit'};
  }
  ${bluePlanetTheme.breakpoints.up('md')} {
    margin-left: ${amount?.md ? `-${amount.md}` : 'inherit'};
    margin-right: ${amount?.md ? `-${amount.md}` : 'inherit'};
  }
  ${bluePlanetTheme.breakpoints.up('lg')} {
    margin-left: ${amount?.lg ? `-${amount.lg}` : 0};
    margin-right: ${amount?.lg ? `-${amount.lg}` : 0};
  }
  `,
);

const ArrowButton = styled.button(({ position }: { position: 'right' | 'left' }) => {
  return css`
    position: absolute;
    z-index: ${zLayer2};
    top: 50%;
    transform: translate3d(0, -50%, 0);
    ${position}: 0;
    ${bluePlanetTheme.breakpoints.up('sm')} {
      ${position === 'right'
        ? css`
            right: ${quarterSpacing};
          `
        : css`
            left: ${quarterSpacing};
          `}
    }
    background-color: ${bluePlanetTheme.bluePlanetPalette.white};
    border-radius: ${bluePlanetTheme.shape.borderRadius}px;
    border: 1px solid ${bluePlanetTheme.bluePlanetPalette.grey.main};
    padding: ${quarterSpacing};
    appearance: none;

    &,
    svg {
      transition: ${bluePlanetTheme.transitions.create(['background-color', 'color'], {
        easing: transitionHover,
      })};
    }
    &:hover {
      color: ${bluePlanetTheme.bluePlanetPalette.indigo.main};
      background-color: ${bluePlanetTheme.bluePlanetPalette.grey.medium};

      svg {
        color: inherit;
      }
    }
  `;
});

export default function Carousel({
  children,
  options,
  slidesWidth,
  bleed,
  highlightedSlideIndex,
  fadeColor,
  eagerLoadNumberOfNeighbours,
}: {
  children: React.ReactNode;
  options?: Omit<EmblaOptionsType, 'dragFree'> & {
    dragFree?: boolean | 'mobile';
    showNavigation?: 'always' | 'on-hover';
  };
  slidesWidth?: ResponsiveBreakpoints;
  bleed?: ResponsiveBreakpoints;
  highlightedSlideIndex?: number;
  fadeColor?: string;
  eagerLoadNumberOfNeighbours?: number;
}) {
  const isSmUp = useMediaQuery(bluePlanetTheme.breakpoints.up('sm'));
  const showNavigation = options?.showNavigation ?? 'always';

  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 'auto',
    ...options,
    dragFree: options?.dragFree === 'mobile' ? !isSmUp : options?.dragFree,
  });
  const [isNavigationVisible, setIsNavigationVisible] = useState(showNavigation === 'always' ? isSmUp : false);
  const [visibleIndex, setVisibleIdx] = React.useState(0);
  const setVisibleIndex = useDebouncedCallback(setVisibleIdx, 200);
  React.useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', () => {
        const index = emblaApi.selectedScrollSnap();
        setVisibleIndex(index);
      });
    }
  }, [emblaApi]);

  useEffect(() => {
    if (highlightedSlideIndex === undefined) return;
    emblaApi?.scrollTo(highlightedSlideIndex);
  }, [highlightedSlideIndex]);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);
  const isNextEnabled = !nextBtnDisabled && isSmUp;
  const isPrevEnabled = !prevBtnDisabled && isSmUp;

  return (
    <Bleed amount={bleed}>
      <SlideContainer
        isNextEnabled={isNextEnabled}
        isPrevEnabled={isPrevEnabled}
        fadeColor={fadeColor}
        ref={emblaRef}
        onPointerEnter={() => setIsNavigationVisible(true)}
        onPointerLeave={() => setIsNavigationVisible(showNavigation === 'always' ? isSmUp : false)}
      >
        <Slides slidesWidth={slidesWidth} bleed={bleed}>
          {React.Children.toArray(children).map((child, index) => (
            <div key={index}>
              {/* // Only render the child if it is within n slides of the visible index */}
              {Math.abs(index - visibleIndex) <= (eagerLoadNumberOfNeighbours ?? 10) ? child : undefined}
            </div>
          ))}
        </Slides>
        {isPrevEnabled && isNavigationVisible && (
          <ArrowButton position="left" onClick={onPrevButtonClick}>
            <ChevronLeftIcon color="grey" />
          </ArrowButton>
        )}
        {isNextEnabled && isNavigationVisible && (
          <ArrowButton position="right" onClick={onNextButtonClick}>
            <ChevronRightIcon color="grey" />
          </ArrowButton>
        )}
      </SlideContainer>
    </Bleed>
  );
}
