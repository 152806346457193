import { UpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import classNames from 'classnames';
import { UpdateContext } from 'domain/shared/Comments/CommentField';
import CommentsContainer from 'domain/shared/Comments/CommentsContainer';
import BannerImage from 'domain/Updates/shared/Show/BannerImage';
import IframelyLinkPreview from 'domain/Updates/shared/Show/IframelyLinkPreview';
import UpdateActions from 'domain/Updates/shared/Show/UpdateActions';
import UpdateHeader from 'domain/Updates/shared/Show/UpdateHeader';
import HtmlContentWithMentions from 'domain/shared/HtmlContentWithMentions';
import { Author } from 'domain/shared/author';
import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';
import { Update as UpdateType, UpdateVisibility } from 'types/content';
import { UserProfile } from 'types/user';
import Attachments from 'ui/domain/Attachments/Attachments';
import Byline from 'ui/domain/Byline';
import Chip from 'ui/elements/Chip';
import HtmlContent from 'ui/elements/HtmlContent';
import Tooltip from 'ui/elements/Tooltip';
import IconButton from 'ui/elements/icons/IconButton';
import PinIcon from 'ui/elements/icons/PinIcon';
import { bluePlanetTheme } from 'ui/theme';
import Card from 'ui/views/cards/Card';
import CardStack from 'ui/views/cards/CardStack';
import MaxWidth from 'ui/views/containers/MaxWidth';
import { communityUrls } from 'urls';
import { scrollolol } from 'util/scroll';
import UpdateStats from '../UpdateStats';
import { richTextContentPreviewParser } from '../utils';
import PreviewImages from './PreviewImages';
import styles from './styles.scss';
import { invalidate } from 'hooks/useSWR';
import { communityActivityKey } from 'pages/Community/Overview/CommunityUpdates/useCommunityActivity';

interface Props {
  className?: string;
  update: UpdateType;
  creator?: UserProfile;
  author: Author;
  viewType?: ViewType;
  shownIn: ShownIn;
  api: UpdateAPI;
  actions?: React.ReactNode;
  canEditUpdate: boolean;
  showIsPinned?: boolean;
  updateContext: UpdateContext;
}

export type ViewType = 'preview' | 'read more';

export type ShownIn = 'community' | 'dashboard' | 'company' | 'boardroom' | 'other';

export const visibilityDisplayString = (visibility: UpdateVisibility) => {
  switch (visibility) {
    case 'Private':
      return 'Shareholders and board members';
    case 'Founders':
      return 'Founders';
    case 'CommunityMembersExceptFounders':
      return 'Investors, mentors & advisors';
    default:
      return '';
  }
};

export default function ShowUpdate(props: Props) {
  const { update, api, showIsPinned, shownIn, actions, author, creator, updateContext } = props;

  const [viewType, setViewType] = useState<ViewType>(props.viewType || 'preview');
  const [likesCount, setLikesCount] = useState(update.likes);
  const [commentsCount, setCommentsCount] = useState(update.responses);
  const [hasLiked, setHasLiked] = useState(update.personalization.hasLiked);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [showComments, setShowComments] = useState<boolean>(viewType !== 'preview' && commentsCount > 0);
  const [initialCommentInputFocus, setInitialCommentInputFocus] = useState(false);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const commentsScrollToAnchorRef = React.useRef<HTMLDivElement>(null);
  const quillRef = React.useRef<ReactQuill>(null);

  const onLike = async () => {
    if (!!update.archived) return;

    if (hasLiked) {
      await api.likes.unLike(update.id);
    } else {
      await api.likes.like(update.id);
    }
    setHasLiked(!hasLiked);
    if (updateContext.type === 'community') {
      invalidate(communityActivityKey(updateContext.community.id));
    }
    const diff = hasLiked ? -1 : 1;
    setLikesCount(likesCount + diff);
  };

  const onClick = (updated: ViewType) => {
    if (viewType === 'preview') {
      api.markAsRead(update.id);
    }
    setViewType(updated);
    setShowComments(true);
  };

  const scrollToCommentInputField = () => {
    // Add delay here because expanding the comments section also expands the update content, meaning the location we should scroll to changes
    setTimeout(() => {
      const commentsContainerTop = commentsScrollToAnchorRef?.current?.getBoundingClientRect()?.top ?? 0;
      scrollolol(commentsContainerTop - 200);
    }, 100);
  };

  const onCommentClick = (setInputFocus: boolean) => {
    setShowComments(true);
    if (setInputFocus) {
      setInitialCommentInputFocus(true);
      quillRef.current?.focus();
      scrollToCommentInputField();
    }
    onClick('read more');
  };

  const { previewHtmlString, imgUrls, firstEmbed } = richTextContentPreviewParser(
    update.content,
    200,
    update.attachments.length,
  );

  const cleaned = DOMPurify.sanitize(update.content, { ALLOWED_TAGS: [] });
  const updateContentHasText = cleaned !== '';

  const showAttachments =
    update.attachments.length > 0 && !(viewType === 'preview' && (imgUrls.length > 0 || firstEmbed));

  const showRichText =
    updateContentHasText ||
    imgUrls.length > 0 ||
    firstEmbed ||
    (update.attachments.length > 0 && viewType === 'preview');

  return (
    <div className={props.className} ref={containerRef}>
      <CardStack>
        <Card>
          <div className="u-content-spacing-bottom u-flex-align-center u-flex-end">
            <div className="u-flex-grow">
              <Byline author={author} timestamp={update.createdAt}>
                <div className="u-flex-align-baseline u-flex--gap-quarter">
                  {updateContext?.type === 'community' && shownIn !== 'community' && (
                    <span>
                      In{' '}
                      <Link
                        to={communityUrls.overview(updateContext.community.slug)}
                        className="u-color-text text-weight-medium"
                      >
                        {updateContext.community.name}
                      </Link>
                    </span>
                  )}
                  {(updateContext?.type === 'community' || updateContext?.type === 'company') &&
                    updateContext.visibility !== 'CommunityMembers' &&
                    updateContext.visibility !== 'Public' && (
                      <span
                        className={
                          updateContext.type !== 'community' || shownIn === 'community' ? 'u-quarter-spacing-top' : ''
                        }
                      >
                        <Chip
                          fontSize="small"
                          color="grey-medium"
                          label={visibilityDisplayString(updateContext.visibility)}
                        />
                      </span>
                    )}
                </div>
              </Byline>
            </div>
            <div
              className="u-flex u-flex-align-center text-small"
              style={{ color: bluePlanetTheme.bluePlanetPalette.grey.dark }}
            >
              {showIsPinned && (
                <>
                  <div className="u-mobile-down-only">
                    <Tooltip
                      placement="top"
                      arrow={true}
                      open={tooltipIsOpen}
                      onClose={() => setTooltipIsOpen(false)}
                      onOpen={() => setTooltipIsOpen(true)}
                      disableHoverListener
                      title="Pinned by admin"
                    >
                      <div>
                        <IconButton
                          onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                          aria-label="Pinned by admin"
                          color="grey"
                        >
                          <PinIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>
                  <PinIcon
                    className="u-quarter-spacing-right u-mobile-up-only"
                    fontSize="small"
                    style={{ marginLeft: 2 }}
                  />
                  <span className="u-mobile-up-only text-small u-half-spacing-right">Pinned by admin</span>
                </>
              )}
              {actions}
            </div>
          </div>
          <BannerImage imageUrl={update.bannerImage} />
          {update.title && <UpdateHeader content={update} />}
          {showRichText && (
            <MaxWidth width="sm">
              <HtmlContentWithMentions
                onClick={() => onClick('read more')}
                className={classNames(styles.updateContent, 'u-content-spacing-bottom', {
                  'data-content-read-more': viewType === 'preview',
                })}
              >
                {viewType === 'read more' ? update.content : previewHtmlString}
              </HtmlContentWithMentions>
            </MaxWidth>
          )}
          {firstEmbed && viewType === 'preview' && <HtmlContent embeddingIsAllowed>{firstEmbed}</HtmlContent>}
          {!firstEmbed && imgUrls.length > 0 && viewType === 'preview' && (
            <div onClick={() => onClick('read more')} style={{ cursor: 'pointer' }}>
              <PreviewImages imageUrls={imgUrls} />
            </div>
          )}
          {update.linkPreview && <IframelyLinkPreview link={update.linkPreview} />}
          {showAttachments && (
            <Attachments
              contentId={update.id}
              attachmentIds={update.attachments}
              listAttachments={api.attachments.list}
              downloadAttachment={api.attachments.download}
              isPreview={viewType === 'preview'}
              imgCount={imgUrls.length}
              onReadMore={() => onClick('read more')}
            />
          )}
          <UpdateStats
            api={api}
            numLikes={likesCount}
            numComments={commentsCount}
            canEditUpdate={props.canEditUpdate}
            update={update}
            onCommentClick={() => {
              setShowComments(!showComments);
              setInitialCommentInputFocus(false);
            }}
          />
        </Card>
        <UpdateActions
          creator={creator}
          hasLiked={hasLiked}
          content={update}
          onLike={onLike}
          onCommentClick={() => onCommentClick(true)}
          getLikes={api.likes.list}
        />
        <span ref={commentsScrollToAnchorRef}></span>
        {showComments && (
          <CommentsContainer
            autoFocus={initialCommentInputFocus}
            editorRef={quillRef}
            onInputClicked={scrollToCommentInputField}
            updateId={update.id}
            commentsCount={commentsCount}
            getComments={api.comments.list}
            postComment={(contentId, comment) =>
              api.comments.post(contentId, comment).then(response => {
                setCommentsCount(commentsCount + 1);
                return response;
              })
            }
            replyToComment={(contentId, commentId, comment) =>
              api.comments.replyToComment(contentId, commentId, comment).then(response => {
                setCommentsCount(commentsCount + 1);
                return response;
              })
            }
            getReplies={api.comments.getReplies}
            deleteComment={api.comments.delete}
            context={props.updateContext}
          />
        )}
      </CardStack>
    </div>
  );
}
