import React from 'react';
import { Involvement } from 'types/user';
import styled from '@emotion/styled';
import { involvementLabels } from '../UserInvolvementForm';
import TeacherIcon from 'ui/elements/icons/TeacherIcon';
import { contentSpacing, halfSpacing } from 'ui/theme/themeConstants';
import TieIcon from 'ui/elements/icons/TieIcon';
import NoteIcon from 'ui/elements/icons/NoteIcon';
import MortarboardIcon from 'ui/elements/icons/MortarboardIcon';
import { bluePlanetTheme } from 'ui/theme';

const List = styled.ul`
  display: flex;
  gap: ${contentSpacing};

  ${bluePlanetTheme.breakpoints.down('sm')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: ${halfSpacing};
  }
`;

export default function InvolvementList({ involvement }: { involvement: Involvement }) {
  if (!involvement || !Object.values(involvement).includes(true)) {
    return null;
  }

  return (
    <>
      <div className="u-content-spacing-top">
        <List>
          {involvement.provideGeneralFeedback && (
            <div>
              <NoteIcon fontSize="large" />
              <span className="text-body">{involvementLabels.provideGeneralFeedback}</span>
            </div>
          )}
          {involvement.openForBoardMemberPosition && (
            <div>
              <TieIcon fontSize="large" />
              <span className="text-body">{involvementLabels.openForBoardMemberPosition}</span>
            </div>
          )}
          {involvement.openForAdvisoryBoardPosition && (
            <div>
              <MortarboardIcon fontSize="large" />
              <span className="text-body">{involvementLabels.openForAdvisoryBoardPosition}</span>
            </div>
          )}

          {involvement.openForMentorPosition && (
            <div>
              <TeacherIcon fontSize="large" />
              <span className="text-body">{involvementLabels.openForMentorPosition}</span>
            </div>
          )}
        </List>
      </div>
    </>
  );
}
