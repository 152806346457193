import React, { CSSProperties } from 'react';
import { staticFileLocations } from 'urls';
import { resize } from 'util/cloudinary';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';

interface Props {
  companySlug?: string;
  imageURL?: string;
  style?: CSSProperties;
  width?: number | 'auto';
}

const BackgroundImg = styled.div`
  width: 100%;
  overflow: hidden;
  padding-bottom: 24%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${bluePlanetTheme.breakpoints.up('lg')} {
    padding-bottom: 16%; // this will crop the image a bit on large screens
  }
`;

export default function Banner({ imageURL, style }: Props) {
  const backgroundImage = resize(imageURL || staticFileLocations.defaultCompanyImage, { width: 'auto' });
  return (
    <BackgroundImg
      style={{
        ...style,
        backgroundImage: `url(${backgroundImage})`,
      }}
    />
  );
}
