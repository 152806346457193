import { OnboardingAPI } from 'apis/OnboardingAPI';
import React, { useState } from 'react';
import { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import { CompanyProfile } from 'types/company';
import { track } from 'util/analytics';
import { userDashboardKey } from 'pages/Dashboard/useUserDashboardData';
import { invalidate } from 'hooks/useSWR';
import UploadPitchDeck from './UploadPitchDeck';
import { MapboxResponse, mapToLocation } from 'apis/MapboxAPI';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { externalUrls, userUrls } from 'urls';
import { externalUrlFetcher } from 'apis/utils';
import useSessionStorage from 'hooks/useSessionStorage';
import Button from 'ui/elements/buttons/Button';
import { logError } from 'util/logging';
import ButtonList from 'ui/elements/buttons/ButtonList';
import ProgressBar from 'ui/elements/Progress/ProgressBar';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import Quotes from './Quotes';
import { Link } from 'react-router-dom';
import { companyProfileKey } from 'apis/CompanyAPI/companies/useCompanyProfile';
import useNotify from 'hooks/useNotify';

const PercentageText = styled.div`
  font-size: 2rem;
  color: ${bluePlanetTheme.bluePlanetPalette.blue.main};
  font-weight: 700;
  line-height: 1.67rem;
`;

export function useAiOnboardingSessionStorage() {
  const [isAiOnboarding, setIsAiOnboarding] = useSessionStorage('ai-onboarding', false);
  return [isAiOnboarding, setIsAiOnboarding] as const;
}

export default function AiOnboarding({
  companyProfile,
  onComplete,
  onClose,
  onUseManualInput,
}: {
  companyProfile: CompanyProfile;
  onComplete: () => void;
  onClose: (status: 'Skipped' | 'Completed') => void;
  onUseManualInput: () => void;
}) {
  function onCloseWizard(status: 'Skipped' | 'Completed', reload = true) {
    onClose(status);
    if (reload) {
      invalidate(userDashboardKey);
    }
    track('company-onboarding-completed');
  }

  const [_, setAiOnboardingSessionStorage] = useAiOnboardingSessionStorage();

  const [aiProgress, setAiProgress] = React.useState<
    'idle' | 'confirm-terms' | 'uploading' | 'generating-profile' | 'completed' | 'failed'
  >('idle');

  type Steps =
    | 'pdf-uploaded'
    | 'mission'
    | 'executive-summary'
    | 'industries'
    | 'impact-goals'
    | 'stages'
    | 'basic-profile';

  const initState = {
    'pdf-uploaded': 0,
    mission: 0,
    'executive-summary': 0,
    industries: 0,
    'impact-goals': 0,
    stages: 0,
    'basic-profile': 0,
  };

  // estimated time per step in ms
  const estimatedStepDuration: Record<Steps, number> = {
    'pdf-uploaded': 1000,
    mission: 30000,
    'executive-summary': 60000,
    'impact-goals': 5000,
    industries: 12000,
    stages: 10000,
    'basic-profile': 15000,
  };
  const [stepsProgress, setStepsProgress] = useState(initState);

  const completeStep = (stepName: Steps) => {
    setStepsProgress(prevProgress => ({
      ...prevProgress,
      [stepName]: 100,
    }));
  };

  const totalDuration = Object.values(estimatedStepDuration).reduce((sum, duration) => sum + duration, 0);
  const updateInterval = 100;

  const calculateTotalProgress = () => {
    return Object.entries(stepsProgress).reduce((sum, [step, progress]) => {
      return sum + (progress * estimatedStepDuration[step as Steps]) / totalDuration;
    }, 0);
  };
  const totalProgress = calculateTotalProgress();
  const percentage = Math.min(Math.ceil(totalProgress), 100);

  const startStepProgress = (step: Steps) => {
    const maxProgressBeforeComplete = 95;
    const stepUpdateAmount = (100 * updateInterval) / estimatedStepDuration[step];
    setInterval(() => {
      setStepsProgress(prevProgress => {
        const currentProgress = prevProgress[step];
        if (currentProgress < maxProgressBeforeComplete) {
          return {
            ...prevProgress,
            [step]: Math.min(currentProgress + stepUpdateAmount, maxProgressBeforeComplete),
          };
        }
        return prevProgress;
      });
    }, updateInterval);
  };

  const [formData] = useState(new FormData());

  function startStep<T>(step: Steps, promise: Promise<T>) {
    startStepProgress(step);
    return promise.then(data => {
      completeStep(step);
      return data;
    });
  }

  const generateProfile = async () => {
    setAiProgress('generating-profile');
    setAiOnboardingSessionStorage(true);

    const industriesPromise = startStep(
      'industries',
      OnboardingAPI.suggestions.generateIndustries(companyProfile.slug),
    );
    const impactGoalsPromise = startStep(
      'impact-goals',
      OnboardingAPI.suggestions.generateImpactGoals(companyProfile.slug),
    );
    const stagesPromise = startStep('stages', OnboardingAPI.suggestions.generateStages(companyProfile.slug));
    const missionPromise = startStep('mission', OnboardingAPI.suggestions.generateMission(companyProfile.slug));
    const executiveSummaryPromise = startStep(
      'executive-summary',
      OnboardingAPI.suggestions.generateExecutiveSummary(companyProfile.slug),
    );

    const basicProfilePromise = startStep(
      'basic-profile',
      OnboardingAPI.suggestions.generateBasicProfile(companyProfile.slug).then(data => {
        return data;
      }),
    );

    const [basicProfile] = await Promise.all([
      basicProfilePromise,
      missionPromise,
      executiveSummaryPromise,
      industriesPromise,
      impactGoalsPromise,
      stagesPromise,
    ]);

    const locationResponse = (await (basicProfile.location
      ? externalUrlFetcher(externalUrls.mapboxPlacesPermanent(basicProfile.location, 1, 'places'))
      : Promise.resolve())) as MapboxResponse | undefined;
    try {
      const location = locationResponse?.features.map(mapToLocation);
      if (location && location[0]) {
        await companiesApi.update(companyProfile.slug, {
          location: location[0],
        });
      }
    } catch (e) {
      // don't show an error to the user if mapbox fails, just log it.
      logError(e, 'Failed to update company location');
    }
    // Let the user see that the generation is complete before closing the wizard

    invalidate(companyProfileKey(companyProfile.slug));
    setTimeout(onComplete, 1500);
  };
  const notify = useNotify();

  const onAcceptTerms = async () => {
    setAiProgress('uploading');

    try {
      startStepProgress('pdf-uploaded');
      await OnboardingAPI.saveOnboardingPDF(companyProfile.slug, formData);
      completeStep('pdf-uploaded');
    } catch (e) {
      setAiProgress('idle');

      notify('error', 'We were unable to process your file. Please try with another file, or go back to manual input');
      return;
    }

    try {
      await generateProfile();
    } catch (e) {
      logError(e, 'AI onboarding failed.');
      // Most likely parts of the AI onboarding succeeded, so we don't want to show the user an error.
      setAiProgress('failed');
      onComplete();
    }
  };

  if (aiProgress === 'idle')
    return (
      <>
        <Title>Upload your company presentation to get started </Title>
        <UploadPitchDeck
          onFileSelected={(file: File) => {
            formData.append('file', file);
            setAiProgress('confirm-terms');
          }}
          onUseManualInput={onUseManualInput}
        />
      </>
    );

  if (aiProgress === 'confirm-terms')
    return (
      <>
        <Title>Accept terms</Title>
        <Content>
          I confirm that data from my uploaded company presentation can be used to create my profile. By using
          CrowdWorks AI, I agree to the{' '}
          <Link className="text-link" target="_blank" to={userUrls.legal.privacyPolicy('artificial-intelligence')}>
            Content Generation Terms
          </Link>{' '}
          and that I am responsible for any text I use.
        </Content>
        <DialogActions>
          <ButtonList>
            <Button kind="primary" onClick={onAcceptTerms}>
              Confirm
            </Button>
            <Button kind="tertiary" onClick={onUseManualInput}>
              Cancel
            </Button>
          </ButtonList>
        </DialogActions>
      </>
    );

  if (aiProgress === 'failed') {
    return (
      <div>
        We are really sorry, but something went wrong. The error is logged and we will look into it.
        <Button kind="primary" onClick={() => onCloseWizard('Skipped')}>
          Close wizard
        </Button>
      </div>
    );
  }

  return (
    <Content style={{ margin: '10rem 0' }} className="u-flex u-flex-align-center u-flex--column u-content-spacing-top">
      <PercentageText>{percentage}%</PercentageText>
      <div className="text-body u-content-spacing-bottom">Please wait while we get to know you better...</div>
      <ProgressBar color="blue" percentage={percentage} />
      <div style={{ textAlign: 'center' }} className="u-section-spacing-top">
        <div className="text-metadata" style={{ fontWeight: '500', fontSize: '18px' }}>
          Did you know?
        </div>
        <Quotes />
      </div>
    </Content>
  );
}
