import { getOrUndefined } from 'util/resource';
import { useOrganizations } from 'apis/OrganizationAPI/organizations/useOrganizations';
import useSuperAdmin from 'auth/useSuperAdmin';

export default function useOrganizationRole(slug: string | undefined) {
  const { resource: organizations } = useOrganizations();

  const organization = getOrUndefined(organizations)?.find(org => org.slug === slug);
  const isAdmin = organization?.isAdmin === true;

  const { canReadEverything, canWriteEverything } = useSuperAdmin();

  return {
    isFetching: organizations.state === 'fetching',
    isFetched: organizations.state === 'fetched',
    isWriteAdmin: isAdmin || canWriteEverything,
    isReadAdmin: isAdmin || canReadEverything,
  };
}
