import React from 'react';
import Button from 'ui/elements/buttons/Button';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import useLazyResource from 'util/resource/useLazyResource';
import useNotify from 'hooks/useNotify';

interface Props {
  title: string;
  resourceBeingDeleted?: string;
  isOpen: boolean;
  onDelete?: () => Promise<any>;
  onSuccess?: () => void;
  onClose: () => void;
}

export default function DeleteDialog({ isOpen, onClose, title, resourceBeingDeleted, onDelete, onSuccess }: Props) {
  const notify = useNotify();
  const [performDelete, isDeleting] = useLazyResource(onDelete ?? Promise.resolve, {
    onSuccess: () => {
      onSuccess && onSuccess();
      onClose();
    },
    onFailure: () => notify('error', 'Unable to delete' + resourceBeingDeleted ? ' ' + resourceBeingDeleted : ''),
  });

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={'xs'} mobileLayout="drawer">
      <Title onClose={onClose}>{title}</Title>
      <Content>
        Are you sure you want to delete
        {resourceBeingDeleted && (
          <>
            {' '}
            <b>&quot;{resourceBeingDeleted}&quot;</b>
          </>
        )}
        ?
        <br />
        This action cannot be undone.
      </Content>
      <DialogActions>
        <Button isLoading={isDeleting} kind="primary" color="red" onClick={performDelete}>
          Delete
        </Button>
        <Button kind="tertiary" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
