import React from 'react';
import SvgIcon, { IconProps } from './SvgIcon';

export default function TeacherIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M2.981 2h14c1.886 0 2.829 0 3.415.586.585.586.585 1.528.585 3.414v6c0 1.886 0 2.828-.585 3.414C19.81 16 18.867 16 16.98 16h-7M10.981 6.5h6M2.981 17v-4c0-.943 0-1.414.293-1.707C3.567 11 4.04 11 4.981 11h2m-4 6h4m-4 0v5m4-11v6m0-6h6m-6 6v5" />
      <path stroke="#141B34" strokeWidth="1.5" d="M6.981 6.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
    </SvgIcon>
  );
}
