import styled from '@emotion/styled';
import React, { HtmlHTMLAttributes } from 'react';
import ChevronDownIcon from 'ui/elements/icons/ChevronDownIcon';
import ChevronUpIcon from 'ui/elements/icons/ChevronUpIcon';
import { bluePlanetTheme } from 'ui/theme';
import { quarterSpacing } from 'ui/theme/themeConstants';
import { SortDirection } from './useSorting';

const Button = styled.button`
  outline: none;

  svg {
    height: 12px;
    width: 12px;
    left: 0;
    position: absolute;
  }

  &:hover,
  &:focus {
    color: ${bluePlanetTheme.bluePlanetPalette.indigo.dark};
  }
`;

type Props = {
  sortDirection?: SortDirection;
} & HtmlHTMLAttributes<HTMLButtonElement>;

export default function SortButton({ sortDirection, children, onClick, style, ...rest }: Props) {
  return (
    <Button onClick={onClick} style={{ display: 'flex', ...style }} {...rest}>
      <span
        style={{ position: 'relative', display: 'inline-block', height: 20, width: 15, marginRight: quarterSpacing }}
      >
        <ChevronUpIcon
          strokeWidth={4}
          color={sortDirection === 'desc' ? 'dark-grey' : 'inherit'}
          style={{ top: 0 }}
        ></ChevronUpIcon>
        <ChevronDownIcon
          strokeWidth={4}
          color={sortDirection === 'asc' ? 'dark-grey' : 'inherit'}
          style={{ bottom: 0 }}
        ></ChevronDownIcon>
      </span>
      {children}
    </Button>
  );
}
