import { useState } from 'react';

export type SortDirection = 'asc' | 'desc';
export type SortDirectionCycle = 'asc first' | 'desc first';

export interface SortOptions {
  sortBy: string;
  sortOrder: SortDirection;
}

// Cycle through sorting
// With sortOrderShuffleDirection set to 'asc first' (default): Asc -> Desc -> None
// With sortOrderShuffleDirection set to 'desc first': Desc -> Asc -> None
export function getNextState(
  columnName: string,
  prevState: SortOptions | undefined,
  sortOrderCycleDirection: 'asc first' | 'desc first' = 'asc first',
): SortOptions | undefined {
  if (!prevState || prevState.sortBy !== columnName) {
    return {
      sortBy: columnName,
      sortOrder: sortOrderCycleDirection === 'asc first' ? 'asc' : 'desc',
    };
  }

  if (prevState.sortBy === columnName) {
    if (
      (sortOrderCycleDirection === 'asc first' && prevState.sortOrder === 'asc') ||
      (sortOrderCycleDirection === 'desc first' && prevState.sortOrder === 'desc')
    ) {
      return { sortBy: columnName, sortOrder: prevState.sortOrder === 'asc' ? 'desc' : 'asc' };
    } else {
      return undefined;
    }
  }
  return undefined;
}

export default function useSorting(initialSortOptions?: SortOptions) {
  const [sortOptions, setSortOptions] = useState<SortOptions | undefined>(initialSortOptions);

  function onSort(sortOptions: SortOptions | undefined) {
    setSortOptions(sortOptions);
  }

  return { sortOptions, onSort };
}
