import React from 'react';
import { DashboardMessage as DashboardMessageDTO } from 'apis/DashboardAPI';
import FinishUserProfile from './FinishInvestorProfile';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';
import FinishCompanyProfile from './FinishCompanyProfile';

export const healthArenaMessageKey = `has-dismissed-health-arena-message`;

interface Props {
  messages: DashboardMessageDTO[];
}

export default function CompleteProfileMessage({ messages }: Props) {
  const firstMessage = messages.find(message =>
    [
      'thanks-for-signing-up-as-investor',
      'thanks-for-signing-up-as-other',
      'welcome-to-company-profile-as-new-company-admin',
    ].includes(message.type),
  );

  if (!firstMessage) {
    return null;
  }

  const message = () => {
    switch (firstMessage.type) {
      case 'thanks-for-signing-up-as-investor':
      case 'thanks-for-signing-up-as-other':
        return <FinishUserProfile key="thanks-for-signing-up-as-user" />;
      case 'welcome-to-company-profile-as-new-company-admin':
        return (
          <FinishCompanyProfile key="welcome-to-company-profile-as-new-company-admin" company={firstMessage.company} />
        );
      default:
        return null;
    }
  };
  return <MobileMargin className="u-section-spacing-bottom">{message()}</MobileMargin>;
}
