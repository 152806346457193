import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export default function useContainerWidth(ref: React.RefObject<HTMLElement>, deps: any[] = []) {
  const [width, setWidth] = useState(ref.current?.offsetWidth);

  // Update the width when dependencies change
  useEffect(() => {
    setWidth(ref.current?.offsetWidth);
  }, deps);

  const debouncedSetWidth = useDebouncedCallback((newWidth: number | undefined) => setWidth(newWidth), 200);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = ref.current?.offsetWidth;
      debouncedSetWidth(newWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      debouncedSetWidth.cancel(); // Cancel any pending debounced calls
    };
  }, [debouncedSetWidth, ref]);

  return width;
}
