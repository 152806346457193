import React from 'react';
import { UserProfile } from 'types/user';
import Avatar from 'ui/elements/avatars/Avatar';
import Card from 'ui/views/cards/Card';
import ConversationLink from 'domain/users/Common/ConversationLink';

interface Props {
  user: UserProfile;
  onClick: (id: UUID) => void;
}

export default function TeamMember({ user, onClick }: Props) {
  return (
    <Card className="u-flex u-flex--column u-flex-align-center u-relative u-fullHeight" padding="none">
      <button className="u-content-padding u-fullWidth text-align-left" onClick={() => onClick(user.cwUserId)}>
        <div className="u-flex u-fullWidth">
          <Avatar size={80} imageUrl={user.imageUrl} userName={user.name} resize={160} />
          <div className="u-content-spacing-left">
            <h3 className="text-h3">{user.name}</h3>
            <span className="text-metadata">{[user.position, user.employer].filter(Boolean).join(' at ')}</span>
            <div
              className="u-flex u-flex--gap-half u-half-spacing-top u-flex--wrap"
              // we need to stop propagation to prevent the surrounding team member card clickevent from firing when clicking on the links
              // or clicking inside the dialog that the links open (before, clicking anywhere in the chat dialog that opened would open the team member profile dialog)
              onClick={event => event.stopPropagation()}
            >
              <ConversationLink user={user} hasIcon={false} link="normal" />
              {user.linkedInUrl && user.linkedInUrl.trim() !== '' && (
                <a
                  href={`https://www.linkedin.com/in/${user.linkedInUrl}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-link-small"
                >
                  <span>LinkedIn</span>
                </a>
              )}
            </div>
          </div>
        </div>
      </button>
    </Card>
  );
}
