import { FormHelperText, useMediaQuery } from '@mui/material';
import { saveUser } from 'actions/userProfiles';
import LocationInput from 'domain/shared/Location/LocationInput';
import { useFormik } from 'formik';
import useNotify from 'hooks/useNotify';
import React, { useEffect } from 'react';
import { PatchUserPayload, SelfUserProfile } from 'types/user';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import Label from 'ui/elements/form/Label';
import SelectCloud from 'ui/elements/form/SelectCloud/SelectCloud';
import { bluePlanetTheme } from 'ui/theme';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import useLazyResource from 'util/resource/useLazyResource';
import * as Yup from 'yup';

const taxResidencyOptions = [
  'Sweden',
  'Denmark',
  'Norway',
  'Finland',
  'Luxemburg',
  'Germany',
  'Austria',
  'Switzerland',
].map(value => ({
  value,
  name: value,
}));

export default function SebLegalForm({
  onBack,
  onStart,
  onComplete,
  userProfile,
}: {
  onComplete: () => void;
  onStart?: () => void;
  onBack?: () => void;
  userProfile: SelfUserProfile;
}) {
  const notify = useNotify();
  const [updateUser, isSaving] = useLazyResource((user: PatchUserPayload) => saveUser(user), {
    onSuccess: onComplete,
    onFailure: () => notify('error', 'Unable to save changes'),
  });

  const formik = useFormik<{ taxResidency?: string; isMifidQualifiedProfessionalInvestor?: boolean }>({
    initialValues: {
      taxResidency: userProfile.taxResidency,
      isMifidQualifiedProfessionalInvestor: userProfile.isMifidQualifiedProfessionalInvestor || undefined,
    },
    validationSchema: Yup.object().shape({
      taxResidency: Yup.string().required('Please select your tax residency'),
      isMifidQualifiedProfessionalInvestor: Yup.boolean().required(
        'Please select if you are a professional investor according to MIFID requirements',
      ),
    }),
    onSubmit: async values => {
      updateUser({
        taxResidency: values.taxResidency,
        isMifidQualifiedProfessionalInvestor: values.isMifidQualifiedProfessionalInvestor,
      });
    },
  });

  useEffect(() => {
    onStart && onStart();
  }, [onStart]);

  const onSelectTaxResidency = (value: string) => {
    formik.setFieldValue('taxResidency', value);
  };

  const isSmDown = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  return (
    <>
      <Content>
        <Label>Where are you tax resident?</Label>
        <SelectCloud
          options={taxResidencyOptions}
          onSelect={v => onSelectTaxResidency(v.value)}
          values={
            formik.values.taxResidency ? [{ value: formik.values.taxResidency, name: formik.values.taxResidency }] : []
          }
          customInputComponent={
            <LocationInput
              style={{ width: isSmDown ? '100%' : '300px' }}
              autoFocus
              searchArea="countries"
              name="taxResidency"
              onChange={location => location && onSelectTaxResidency(location?.place)}
            />
          }
        />
        {formik.errors.taxResidency && formik.touched.taxResidency && (
          <FormHelperText error>{formik.errors.taxResidency}</FormHelperText>
        )}

        <Label className="u-section-spacing-top">Are you a professional investor and qualified in MIFID?</Label>
        <SelectCloud
          options={[
            { value: 'no', name: 'No, I am not' },
            { value: 'yes', name: 'Yes, I am' },
          ]}
          onSelect={v => formik.setFieldValue('isMifidQualifiedProfessionalInvestor', v.value === 'yes')}
          values={
            formik.values.isMifidQualifiedProfessionalInvestor !== undefined
              ? [
                  formik.values.isMifidQualifiedProfessionalInvestor
                    ? { value: 'yes', name: 'Yes, I am' }
                    : { value: 'no', name: 'No, I am not' },
                ]
              : []
          }
        />
        {formik.errors.isMifidQualifiedProfessionalInvestor && formik.touched.isMifidQualifiedProfessionalInvestor && (
          <FormHelperText error>{formik.errors.isMifidQualifiedProfessionalInvestor}</FormHelperText>
        )}
      </Content>
      <DialogActions align="space-between">
        <ButtonList>
          <Button
            kind="primary"
            onClick={formik.submitForm}
            isLoading={isSaving}
            className="track-investor-onboarding-preference-continue track-wizard-step-continue"
          >
            Next
          </Button>
          {onBack && (
            <Button kind="primary" onClick={onBack} color="greyLight">
              Back
            </Button>
          )}
        </ButtonList>
      </DialogActions>
    </>
  );
}
