import { useLayoutEffect } from 'react';

export default function useScrollToElement(elementId: string, shouldScroll = true) {
  useLayoutEffect(() => {
    if (shouldScroll) {
      const element = document.getElementById(elementId);
      element?.scrollIntoView({});
    }
  }, [shouldScroll, elementId]);
}
