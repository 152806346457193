import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import { useLoginState } from './useLoginWithRedirect';

export function useLogout() {
  const { logout } = useAuth0();
  const { reset } = useLoginState();

  return {
    logout: (options?: LogoutOptions) => {
      reset();
      logout(options);
    },
  };
}
