import { saveUser } from 'actions/userProfiles';
import useNotify from 'hooks/useNotify';
import { pluck } from 'ramda';
import React from 'react';
import { useEffect, useState } from 'react';
import { allInterests, Interest, PatchUserPayload, SelfUserProfile } from 'types/user';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import SelectCloud from 'ui/elements/form/SelectCloud/SelectCloud';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import useLazyResource from 'util/resource/useLazyResource';

export default function InterestsForm({
  onBack,
  onStart,
  onComplete,
  closeWizard,
  userProfile,
}: {
  onComplete: () => void;
  onStart?: () => void;
  onBack?: () => void;
  closeWizard?: () => void;
  userProfile: SelfUserProfile;
}) {
  const notify = useNotify();
  const [selectedInterests, setSelectedInterests] = useState<{ key: Interest; name: string }[]>(
    userProfile.interests.map(key => ({ key, name: allInterests[key] })),
  );
  const [updateUser, isSaving] = useLazyResource((user: PatchUserPayload) => saveUser(user), {
    onSuccess: onComplete,
    onFailure: () => notify('error', 'Unable to save changes'),
  });

  useEffect(() => {
    onStart && onStart();
  }, [onStart]);

  return (
    <>
      <Content>
        <SelectCloud
          options={Object.keys(allInterests).map((key: Interest) => ({ key, name: allInterests[key] }))}
          values={selectedInterests}
          onSelect={value => setSelectedInterests([...selectedInterests, value])}
          onRemove={value => setSelectedInterests(selectedInterests.filter(it => it !== value))}
        />
      </Content>
      <DialogActions align="space-between">
        <ButtonList>
          <Button
            kind="primary"
            onClick={() => updateUser({ interests: pluck('key', selectedInterests) })}
            isLoading={isSaving}
            className="track-investor-onboarding-interests-continue track-wizard-step-continue"
          >
            {closeWizard ? 'Next' : 'Update'}
          </Button>
          {onBack && (
            <Button kind="primary" onClick={onBack} color="greyLight">
              Back
            </Button>
          )}
        </ButtonList>
        {closeWizard && (
          <Button onClick={closeWizard} kind="tertiary" color="grey">
            Pick it up later
          </Button>
        )}
      </DialogActions>
    </>
  );
}
