import {
  Dialog,
  DialogContentProps,
  DialogTitleProps,
  IconButton,
  DialogActions as MuiDialogActions,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  Slide,
  SlideProps,
} from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import ChevronLeftIcon from 'ui/elements/icons/ChevronLeftIcon';
import { bluePlanetTheme } from 'ui/theme';
import { contentSpacing, halfSpacing, sectionSpacing } from 'ui/theme/themeConstants';

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function MobileDialog({
  open,
  onClose,
  children,
}: {
  open: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}) {
  return (
    <Dialog
      fullScreen
      fullWidth
      open={open}
      onClose={onClose}
      scroll="paper"
      PaperProps={{
        style: {
          maxWidth: '100%',
        },
      }}
      TransitionComponent={Transition}
    >
      <div className="u-flex u-flex-grow u-flex-space-between u-flex--column">{children}</div>
    </Dialog>
  );
}

export function DialogContent(props: DialogContentProps & { paddingX: 'double' | 'default' | 'half' | 'none' }) {
  const { paddingX, ...rest } = props;
  const paddingXValue =
    props.paddingX === 'double'
      ? sectionSpacing
      : props.paddingX === 'default'
        ? contentSpacing
        : props.paddingX === 'half'
          ? halfSpacing
          : props.paddingX === 'none'
            ? 0
            : undefined;
  return (
    <MuiDialogContent
      style={{ ...props.style }}
      {...rest}
      sx={{
        ...props.sx,
        paddingX: paddingXValue,
        overflowY: 'visible',
        [bluePlanetTheme.breakpoints.down('md')]: {
          paddingX,
        },
      }}
    />
  );
}

export function DialogActions(props: { style?: React.CSSProperties; children: React.ReactNode }) {
  return (
    <MuiDialogActions
      style={props.style}
      sx={{
        justifyContent: 'start',
        position: 'sticky',
        zIndex: 10,
        bottom: '0',
        backgroundColor: bluePlanetTheme.bluePlanetPalette.white,
        boxShadow: bluePlanetTheme.shadows[1],
        [bluePlanetTheme.breakpoints.down('md')]: {
          padding: `${contentSpacing} ${halfSpacing}`,
        },
      }}
    >
      <>{props.children}</>
    </MuiDialogActions>
  );
}

type TitleProps = DialogTitleProps & {
  onClose?: () => void;
};

export function DialogTitle(props: TitleProps) {
  return (
    <MuiDialogTitle
      style={{ padding: `${contentSpacing} ${halfSpacing} ${contentSpacing} 0` }}
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 10,
        backgroundColor: bluePlanetTheme.bluePlanetPalette.white,
      }}
    >
      <div className="u-flex-space-between u-flex-align-center">
        {props.onClose && (
          <IconButton color="inherit" onClick={props.onClose} aria-label="Close">
            <ChevronLeftIcon />
          </IconButton>
        )}
        <div className={classNames('u-flex u-flex-align-center u-flex-1', { ['u-half-spacing-left']: !props.onClose })}>
          {props.children}
        </div>
      </div>
    </MuiDialogTitle>
  );
}
