import { asUserAuthor } from 'domain/shared/author';
import React from 'react';
import { CommunityUpdate, ContentUpdateDTO } from 'types/content/update';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import { communityUpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import useNotify from 'hooks/useNotify';
import UpdateForm from './UpdateForm';
import useLazyResource from 'util/resource/useLazyResource';
import { TinyCommunity } from 'types/company/community';
import { PostedBy } from './Update';
import { UserProfile } from 'types/user';
import { useCommunitySummary } from 'apis/CompanyAPI/communities/useCommunitySummary';
import Resources from 'util/resource/Resources';

interface Props {
  community: TinyCommunity;
  setActive: (v: boolean) => void;
  user?: UserProfile;
  onComplete: () => void;
  update: CommunityUpdate;
  postedBy: PostedBy;
}

export default function EditUpdateDialog(props: Props) {
  const api = communityUpdateAPI(props.community.id);
  const { resource: communitySummary } = useCommunitySummary(props.community.slug);

  const author = asUserAuthor(props.user);
  const notify = useNotify();

  const [editUpdate, isSaving] = useLazyResource(
    (dto: ContentUpdateDTO) => api.editUpdate(props.update.update.id, dto),
    {
      onSuccess: () => {
        props.onComplete();
        notify('success', 'Post updated!');
      },
      onFailure: () => {
        notify('error', 'Something went wrong when trying to update post');
      },
    },
  );

  const [attachmentsResource] = useResourceLegacy(
    () =>
      props.update.update.attachments.length > 0
        ? api.attachments.list(props.update.update.id)
        : Promise.resolve({ documents: [] }),
    [props.update.update.id],
  );

  return (
    <Resources resources={[attachmentsResource, communitySummary]}>
      {([attachments, { configuration }]) => (
        <UpdateForm
          communityConfiguration={configuration}
          titleDraft={props.update.update.title}
          contentDraft={props.update.update.content}
          bannerImage={props.update.update.bannerImage}
          attachments={attachments}
          author={author}
          selectedVisibility={props.update.visibility}
          linkPreview={props.update.update.linkPreview}
          onSave={(dto, visibility) => editUpdate({ ...dto, visibility })}
          saveButtonText="Save"
          isSaving={isSaving}
          onClose={() => props.setActive(false)}
          community={props.community}
          contentId={props.update.update.id}
          postedBy={props.postedBy}
        />
      )}
    </Resources>
  );
}
