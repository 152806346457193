import useContainerWidth from 'hooks/useContainerWidth';
import React, { useEffect, useMemo, useState } from 'react';
import { Page } from 'react-pdf';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import useDebouncedIntersectionObserver from 'hooks/useDebouncedIntersectionObserver';
import { bluePlanetTheme } from 'ui/theme';
import Loading from 'ui/elements/Loading';
import { useMediaQuery } from '@mui/material';

interface Props {
  pageNumber: number;
  numPages: number;
}

export default function PitchDeckPDF({ pageNumber, numPages }: Props) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const pageRefs = useMemo(
    () => Array.from({ length: numPages ?? 0 }, () => React.createRef<HTMLDivElement>()),
    [numPages],
  );

  const eagerLoadNumberOfNeighbors = 2;

  const width = useContainerWidth(containerRef, [window.innerWidth, numPages]);
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const [isDoneScrolling, setIsDoneScrolling] = useState(false);
  const [firstPageHasRendered, setFirstPageHasRendered] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>();
  function onPageChange(number: number) {
    if (firstPageHasRendered) {
      // We add one, because the intersection observer returns the top most element. If we keep the pointer roughly in the middle we are good
      setCurrentPageNumber(number + 1);
    }
  }
  useDebouncedIntersectionObserver(pageRefs, onPageChange, 100);

  useEffect(() => {
    if (firstPageHasRendered && numPages && pageNumber && pageRefs[pageNumber - 1].current) {
      pageRefs[pageNumber - 1].current?.scrollIntoView({ behavior: 'instant', block: 'start' });
      setIsDoneScrolling(true);
    }
  }, [firstPageHasRendered, numPages, pageNumber, pageRefs]);

  const [placeHolderHeight, setPlaceholderHeight] = useState<number>();

  function handleRenderSuccess() {
    setFirstPageHasRendered(true);
    setCurrentPageNumber(pageNumber);
    if (pageRefs && pageRefs[0]) {
      const firstElement = pageRefs[0];
      if (firstElement) {
        setPlaceholderHeight(firstElement.current?.offsetHeight);
      }
    }
  }

  return (
    <div
      ref={containerRef}
      style={{ maxWidth: (width ?? 800) * 2 }} // Limit zooming
      className="u-flex u-flex--column u-flex--gap-2 u-section-spacing-bottom"
    >
      {Array.from(new Array(numPages), (el, index) => (
        <div
          key={`page_${index + 1}`}
          ref={pageRefs[index]}
          // We don't want to show the first page until we are done scrolling to avoid flashing
          style={{ visibility: isDoneScrolling ? undefined : 'hidden' }}
        >
          {index === 0 ? (
            <Page
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              width={width}
              onRenderSuccess={handleRenderSuccess}
            />
          ) : (
            <div
              style={{
                height: placeHolderHeight,
                width: '100%',
                backgroundColor: bluePlanetTheme.bluePlanetPalette.white,
              }}
            >
              {currentPageNumber && Math.abs(currentPageNumber - (index + 1)) <= eagerLoadNumberOfNeighbors && (
                <Page
                  loading={<Loading className="u-content-spacing-y" position="center" />}
                  pageNumber={index + 1}
                  // Reduce resource consumption on mobile
                  renderAnnotationLayer={isMobile ? false : true}
                  renderTextLayer={false}
                  width={width}
                />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
