import { saveUser } from 'actions/userProfiles';
import { useFormik } from 'formik';
import useNotify from 'hooks/useNotify';
import React, { useEffect } from 'react';
import { Involvement, PatchUserPayload, SelfUserProfile } from 'types/user';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import SelectCloud from 'ui/elements/form/SelectCloud/SelectCloud';
import InlineTooltip from 'ui/elements/Tooltip/InlineTooltip';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import useLazyResource from 'util/resource/useLazyResource';

export default function BoardPosition({
  onBack,
  onStart,
  onComplete,
  closeWizard,
  userProfile,
}: {
  onComplete: () => void;
  onStart?: () => void;
  onBack?: () => void;
  closeWizard?: () => void;
  userProfile: SelfUserProfile;
}) {
  const notify = useNotify();
  const [updateUser, isSaving] = useLazyResource((user: PatchUserPayload) => saveUser(user), {
    onSuccess: onComplete,
    onFailure: () => notify('error', 'Unable to save changes'),
  });

  const formik = useFormik({
    initialValues: {
      involvement: userProfile.involvement,
    },
    onSubmit: async values => {
      updateUser({ involvement: values.involvement });
    },
  });

  useEffect(() => {
    onStart && onStart();
  }, [onStart]);

  const onSelect = (value: string) => {
    if (formik.values.involvement) {
      formik.setFieldValue('involvement.openForBoardMemberPosition', value === 'yes');
    } else {
      const involvement: Involvement = {
        openForBoardMemberPosition: value === 'yes',
        openForAdvisoryBoardPosition: false,
        openForMentorPosition: false,
        openForSweatEquityProjects: false,
        provideGeneralFeedback: false,
      };
      formik.setFieldValue('involvement', involvement);
    }
  };

  return (
    <>
      <Content>
        <SelectCloud
          options={[
            { value: 'yes', name: 'Yes' },
            { value: 'no', name: 'No, not at the moment' },
          ]}
          onSelect={v => onSelect(v.value)}
          values={
            formik.values.involvement
              ? [
                  formik.values.involvement?.openForBoardMemberPosition
                    ? { value: 'yes', name: 'Yes' }
                    : { value: 'no', name: 'No, not at the moment' },
                ]
              : []
          }
        />

        <div className="u-content-spacing-top">
          <InlineTooltip>Don&apos;t worry, you can change your answer at any time.</InlineTooltip>
        </div>
      </Content>
      <DialogActions align="space-between">
        <ButtonList>
          <Button
            kind="primary"
            onClick={formik.submitForm}
            isLoading={isSaving}
            className="track-investor-onboarding-preference-continue track-wizard-step-continue"
          >
            {closeWizard ? 'Next' : 'Update'}
          </Button>
          {onBack && (
            <Button kind="primary" onClick={onBack} color="greyLight">
              Back
            </Button>
          )}
        </ButtonList>
        {closeWizard && (
          <Button onClick={closeWizard} kind="tertiary" color="grey">
            Pick it up later
          </Button>
        )}
      </DialogActions>
    </>
  );
}
