import useSWR from 'hooks/useSWR';
import { useSWRResource } from './useSWRResource';
import { swrOptions } from 'apis/ApiBase';

export default function useResource<T>(
  url: string | undefined,
  options?: { keepPreviousData?: boolean; cacheDurationMs?: number },
) {
  const result = useSWR<T>(url, undefined, {
    ...(options && {
      keepPreviousData: options.keepPreviousData,
      dedupingInterval: options.cacheDurationMs ?? swrOptions.dedupingInterval,
    }),
  });
  return useSWRResource<T>(result);
}
