import React, { useState } from 'react';
import useNotify from 'hooks/useNotify';
import ButtonList from 'ui/elements/buttons/ButtonList';
import SelectCloud from 'ui/elements/form/SelectCloud/SelectCloud';
import Button from 'ui/elements/buttons/Button';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import { Organization } from 'types/organization';
import { useUpdateOrganization } from 'apis/OrganizationAPI/organizations/useUpdateOrganization';
import { Industry } from 'types';
import { notEmpty } from 'util/arrayUtils';
import MaxWidth from 'ui/views/containers/MaxWidth';
import IndustryPicker from 'domain/labels/industries/IndustryPicker';

export const predefinedIndustryNames = [
  'software',
  'ai',
  'fintech',
  'renewable energy',
  'education',
  'health',
  'b2b',
  'b2c',
  'saas',
  'technology',
  'sustainability',
  'consumer',
];

export default function OrganizationIndustriesForm({
  industries,
  organization,
  onComplete,
  onClose,
  onBack,
}: {
  industries: Industry[];
  organization: Organization;
  onComplete: () => void;
  onClose: () => void;
  onBack: () => void;
}) {
  const { details } = organization;
  const notify = useNotify();

  const predefinedIndustries = predefinedIndustryNames
    .map(ind => industries.find(industry => industry.name.toLowerCase() === ind))
    .filter(notEmpty);

  const [selectedIndustries, setSelectedIndustries] = useState<Industry[]>(
    details.industries.length > 0
      ? details.industries.map(i => industries.find(ind => ind.name === i)).filter(notEmpty)
      : [],
  );

  const [updateOrganization, isUpdatingOrganization] = useUpdateOrganization(organization, {
    onSuccess: () => {
      onComplete();
    },
    onFailure() {
      notify('error', 'Failed to update organization industries');
    },
  });

  return (
    <>
      <Content>
        <SelectCloud
          options={predefinedIndustries}
          values={selectedIndustries}
          onSelect={industry => setSelectedIndustries([...selectedIndustries, industry])}
          onRemove={industry => setSelectedIndustries(selectedIndustries.filter(i => i.name !== industry.name))}
          customInputComponent={
            <MaxWidth width="xs">
              <IndustryPicker
                className="u-half-spacing-top"
                autoFocus
                selectedIndustries={selectedIndustries}
                setIndustries={industries => setSelectedIndustries(industries)}
                hideSelectedValues
              />
            </MaxWidth>
          }
        />
      </Content>
      <DialogActions align="space-between">
        <ButtonList>
          <Button
            isLoading={isUpdatingOrganization}
            onClick={() =>
              updateOrganization({
                industries: selectedIndustries.map(i => i.id),
              })
            }
            kind="primary"
          >
            Next
          </Button>
          <Button onClick={onBack} kind="primary" color="greyLight">
            Back
          </Button>
        </ButtonList>
        <Button onClick={onClose} kind="tertiary" color="grey">
          Pick it up later
        </Button>
      </DialogActions>
    </>
  );
}
