import React, { useEffect, useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import { SelfUserProfile, PatchUserPayload } from 'types/user';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import useLazyResource from 'util/resource/useLazyResource';
import useNotify from 'hooks/useNotify';
import { saveUser } from 'actions/userProfiles';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { USER_WIZARD_CONTENT_MIN_HEIGHT } from './UserOnboardingWizard';
import CompanyRegistrationForm from 'pages/Registration/common/CompanyRegistrationForm';
import { NewEntityDTO } from 'pages/Registration/common/actions';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { invalidate } from 'hooks/useSWR';
import { companiesKey } from 'apis/CompanyAPI/companies/useCompanies';
import { userDashboardKey } from 'pages/Dashboard/useUserDashboardData';
import { companyProfileKey } from 'apis/CompanyAPI/companies/useCompanyProfile';
import { CompanyProfile } from 'types/company';
import { communitiesApi, JoinCommunityAs } from 'apis/CompanyAPI/communities/communitiesApi';
import { communityAccessKey } from 'apis/CompanyAPI/companies/useCommunityAccess';
import { communityKey } from 'apis/CompanyAPI/communities/useCommunity';
import { communitiesKey } from 'apis/CompanyAPI/communities/useCommunities';
import ToggleButton, { ToggleButtonGroup } from 'ui/elements/form/choice/ToggleButton';
import { CommunityInviteDetails } from 'types/company/community';
import { useLoginState } from 'auth/useLoginWithRedirect';
import { isInnovasjonNorge } from 'domain/communities/InnovasjonNorge/utils';
import OnboardingWalkthrough from 'pages/Registration/Company/OnboardingWalkthrough';
import useRoute from 'hooks/useRoute';
import featureToggle from 'featureToggle';
import { communityUrls, companyUrls } from 'urls';
import { OnboardingWizardStatus } from 'apis/OnboardingAPI';
import { onboardinWizardStatusKey } from 'pages/Onboarding/InitialLoadWrapper';
import OrganizationAPI from 'apis/OrganizationAPI';
import { organizationKey } from 'apis/OrganizationAPI/organizations/useOrganizations';
import { WizardType } from 'pages/Dashboard/Dashboard';

interface Props {
  onStart?: () => void;
  onComplete: () => void;
  userProfile: SelfUserProfile;
  invite?: CommunityInviteDetails;
  onShowWizard: (wizard: WizardType) => void;
}

export default function ChooseYourRole({ onComplete, onStart, onShowWizard, invite }: Props) {
  const notify = useNotify();
  const canChooseRole = !invite || invite.invitedAs === 'NotSpecified';

  const [showError, setShowError] = useState(false);
  const [role, setRole] = useState<'company' | 'investor' | 'mentor-or-advisor' | 'organization' | null>(
    invite?.invitedAs === 'Company'
      ? 'company'
      : invite?.invitedAs === 'Advisor'
        ? 'mentor-or-advisor'
        : invite?.invitedAs === 'Investor'
          ? 'investor'
          : invite?.invitedAs === 'Organization'
            ? 'organization'
            : null,
  );

  const onRoleChange = (role: 'company' | 'investor' | 'mentor-or-advisor' | 'organization') => {
    setRole(role);
    setShowError(false);
  };

  const { reset: resetLoginState } = useLoginState();
  const { push } = useRoute();

  const [requestToJoinCommunityAsUser] = useLazyResource(
    () => {
      const joinAs: JoinCommunityAs | undefined =
        role === 'investor' ? { type: 'Investor' } : role === 'mentor-or-advisor' ? { type: 'Advisor' } : undefined;

      if (invite && joinAs) {
        return communitiesApi.accessRequest
          .requestToJoin(invite.community.slug, {
            inviteCode: invite.inviteCode,
            joinAs,
          })
          .then(() => {
            // don't need to store the invite in local storage anymore
            resetLoginState();
          });
      } else {
        return Promise.resolve();
      }
    },
    {
      onSuccess: () => {
        //  we need to refetch these in case the user is auto-accepted into the community
        invite?.community?.slug && invalidate(communityAccessKey(invite.community.slug));
        invite?.community.slug && invalidate(communityKey(invite.community.slug));
        //  we need to refetch these to show correct data on the dashboard
        invalidate(communitiesKey);
      },
    },
  );
  const [onSaveUser, isSavingUser] = useLazyResource((user: PatchUserPayload) => saveUser(user), {
    onSuccess: () => {
      requestToJoinCommunityAsUser(undefined);
      invalidate(userDashboardKey);
      onComplete();
    },
    onFailure: () => notify('error', `Unable to save changes`),
  });

  const [onRegisterCompany, isRegisteringCompany] = useLazyResource(
    (newCompany: NewEntityDTO) =>
      companiesApi.registration.registerCompany(
        undefined,
        {
          company: {
            companyName: newCompany.name,
            isCommunity: false,
          },
          originUrl: window.location.toString(),
        },
        { communityCode: invite?.inviteCode },
      ),
    {
      onSuccess: result => {
        resetLoginState();
        // preload company profile. This prevents  a new loading screen when changing to the company wizard
        invalidate<CompanyProfile>(companyProfileKey(result.company.slug), {
          ...result.company,
          hasPublicPitch: true,
          industries: { values: [] },
          stages: { values: [] },
          connectionCount: 0,
          lastUpdated: new Date().toISOString(),
        });
        invalidate(userDashboardKey);
        invalidate(companiesKey);
        invite?.community.slug && invalidate(communityAccessKey(invite?.community.slug));
        invite?.community.slug && invalidate(communityKey(invite?.community.slug));
        //  we need to refetch these to show correct data on the dashboard
        invalidate(communitiesKey);
        if (invite && featureToggle.isMentorCommunity(invite?.community.id)) {
          invalidate<OnboardingWizardStatus>(onboardinWizardStatusKey, { status: 'Completed' });
          push(companyUrls.innovasjonNorge.mentee.overview(result.company.slug, invite?.community.id));
        } else if (invite?.hasApplication) {
          push(communityUrls.overview(invite.community.slug));
        }
        onShowWizard({ type: 'company', company: result.company });
      },
      onFailure: error => notify('error', error || `Failed to register company.`),
    },
  );

  const [onRegisterOrganization, isRegisteringOrganization] = useLazyResource(
    (newOrganization: NewEntityDTO) =>
      OrganizationAPI.registerOrganization(
        undefined,
        {
          organization: {
            organizationName: newOrganization.name,
          },
          originUrl: window.location.toString(),
        },
        { communityCode: invite?.inviteCode },
      ),
    {
      onSuccess: result => {
        resetLoginState();
        invalidate(organizationKey);
        invalidate(userDashboardKey);
        invite?.community.slug && invalidate(communityAccessKey(invite?.community.slug));
        invite?.community.slug && invalidate(communityKey(invite?.community.slug));
        //  we need to refetch these to show correct data on the dashboard
        invalidate(communitiesKey);
        invalidate<OnboardingWizardStatus>(onboardinWizardStatusKey, {
          company: undefined,
          organization: { ...result.organization },
          status: 'InProgress',
        });
        onShowWizard({ type: 'organization', organization: result.organization });
      },
      onFailure: error => notify('error', error || `Failed to register organization.`),
    },
  );

  useEffect(() => {
    onStart && onStart();
  }, [onStart]);

  const canSelectCompany = invite ? invite.companyMembersIsEnabled : true;

  return (
    <>
      <Content style={{ minHeight: USER_WIZARD_CONTENT_MIN_HEIGHT }}>
        {invite && isInnovasjonNorge(invite?.community.communityType) && (
          <OnboardingWalkthrough communityType={invite.community.communityType} />
        )}
        {canChooseRole && (
          <ToggleButtonGroup>
            <ToggleButton
              isSelected={role === 'investor'}
              title="Investor"
              description="I am an investor or scouting for an investment firm"
              onClick={() => onRoleChange('investor')}
            />
            <ToggleButton
              title="Corporate or Organization"
              description="I represent an established company or organization that works with- or invests in startups"
              isSelected={role === 'organization'}
              onClick={() => onRoleChange('organization')}
            />
            {canSelectCompany && (
              <ToggleButton
                title="Start-up or Scale-up"
                description="I am the founder or represent a company that is looking to network & secure funding"
                isSelected={role === 'company'}
                onClick={() => onRoleChange('company')}
              />
            )}
            <ToggleButton
              title="Advisor or mentor"
              description="I am mentoring or act as an advisor for early phase companies"
              isSelected={role === 'mentor-or-advisor'}
              onClick={() => onRoleChange('mentor-or-advisor')}
            />
          </ToggleButtonGroup>
        )}

        {role === 'company' && (
          <div className="u-content-spacing-top u-section-spacing-bottom">
            <CompanyRegistrationForm
              registerAs="company"
              isSaving={isRegisteringCompany}
              submitButtonText="Next"
              onRegisterNew={onRegisterCompany}
              autoFocus
            />
          </div>
        )}

        {role === 'organization' && (
          <div className="u-content-spacing-top u-section-spacing-bottom">
            <CompanyRegistrationForm
              registerAs="organization"
              isSaving={isRegisteringOrganization}
              submitButtonText="Next"
              onRegisterNew={onRegisterOrganization}
              autoFocus
            />
          </div>
        )}
        {showError && <p className="u-half-spacing-top text-error">Please choose your role</p>}
      </Content>
      {role !== 'company' && role !== 'organization' && (
        <DialogActions align="space-between">
          <ButtonList>
            <Button
              onClick={() => {
                if (!role) {
                  setShowError(true);
                } else {
                  onSaveUser({
                    isInvestor: role === 'investor',
                  });
                }
              }}
              kind="primary"
              isLoading={isSavingUser}
              className="track-investor-onboarding-role-continue track-wizard-step-continue"
            >
              Next
            </Button>
          </ButtonList>
        </DialogActions>
      )}
    </>
  );
}
