import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import { useLoginWithRedirect } from 'auth/useLoginWithRedirect';
import React from 'react';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import LockIcon from 'ui/elements/icons/LockIcon';
import { bluePlanetTheme } from 'ui/theme';
import { fontSizeLarge, spacingUnits } from 'ui/theme/themeBluePlanet';
import { contentSpacing, sectionSpacing } from 'ui/theme/themeConstants';
import Card from 'ui/views/cards/Card';
import { CardGrid } from 'ui/views/cards/CardGrid';

export const Body = styled.div`
  margin: 0 ${spacingUnits.half};
  top: calc(-${contentSpacing} - 4vw);
  position: relative;

  ${bluePlanetTheme.breakpoints.up('sm')} {
    top: calc(-70px - 4vw);
  }

  ${bluePlanetTheme.breakpoints.up('md')} {
    top: calc(-120px - 3vw);
  }

  @media (min-width: 1041px) {
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
  }

  ${bluePlanetTheme.breakpoints.up('lg')} {
    margin-left: auto;
    margin-right: auto;
    top: calc(-140px - 3vw);
    max-width: 1000px;
  }
`;

export const CompanyCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingUnits.content};
  ${bluePlanetTheme.breakpoints.up('sm')} {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;

export const ActionCardButtons = styled.div`
  ${bluePlanetTheme.breakpoints.down('md')} {
    margin-top: ${spacingUnits.content};
  }
`;

export const CommunityManagerCard = styled(Card)`
  width: 100%;
  ${bluePlanetTheme.breakpoints.up('md')} {
    width: 45%;
  }
`;

export const ActionCardBody = styled.div`
  display: flex;
  gap: ${contentSpacing};
  padding: 0;
  ${bluePlanetTheme.breakpoints.up('sm')} {
    padding: ${contentSpacing};
  }
  flex-direction: column;
  align-items: center;

  font-weight: ${bluePlanetTheme.typography.fontWeightMedium};
  font-size: ${fontSizeLarge};
`;

export const ActionText = styled.p`
  text-align: center;
  ${bluePlanetTheme.breakpoints.down('sm')} {
    margin-top: ${spacingUnits.half};
  }
`;

export const Container = styled.div(`
    padding-bottom: ${sectionSpacing};
    position: relative;
`);

export const BlurredBackground = styled.div(
  ({ companyApplicationIsEnabled }: { companyApplicationIsEnabled: boolean }) =>
    companyApplicationIsEnabled
      ? ``
      : `
  padding-bottom: ${sectionSpacing};
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('https://res.cloudinary.com/crowdworks/image/upload/f_auto/v1699352078/marketing/backgrounds/tinified/600.jpg');

  ${bluePlanetTheme.breakpoints.up('sm')} {
    padding-bottom: 200px;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
    background-image: url('https://res.cloudinary.com/crowdworks/image/upload/f_auto/v1699352078/marketing/backgrounds/tinified/960.jpg');
  }

  ${bluePlanetTheme.breakpoints.up('md')} {
    background-image: url('https://res.cloudinary.com/crowdworks/image/upload/f_auto/v1699352075/marketing/backgrounds/tinified/1280.jpg');
  }

  ${bluePlanetTheme.breakpoints.up('lg')} {
    background-image: url('https://res.cloudinary.com/crowdworks/image/upload/f_auto/v1699352074/marketing/backgrounds/tinified/1920.jpg');
  }
`,
);

export function BlueCard({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <Card color="blue-dark" style={{ backgroundColor: '#253366' }}>
      <h3 className="text-h4">{title}</h3>
      <p className="u-half-spacing-top text-metadata-regular" style={{ color: '#aeb5cc' }}>
        {children}
      </p>
    </Card>
  );
}

type Props = {
  title: string;
  subtitle?: string;
  redirectOpts?: {
    emailHint?: string;
    returnTo?: string;
    organization?: string;
    joinCommunity?: {
      slug: string;
    };
  };
};

export function RedirectCard({ title, subtitle, redirectOpts }: Props) {
  const isSmallScreen = useMediaQuery(bluePlanetTheme.breakpoints.down('md'));
  const { loginWithRedirect } = useLoginWithRedirect();

  return (
    <Card padding={isSmallScreen ? 'default' : 'double'} className="u-half-spacing-top" color="indigo">
      <ActionCardBody>
        <div className="u-flex u-flex-align-center">
          <IconAvatar color="white" className="u-content-spacing-right">
            <LockIcon />
          </IconAvatar>
          <div className="u-flex u-flex--column">
            <p className="text-weight-bold">{title}</p>
            <p className="text-body">{subtitle}</p>
          </div>
        </div>
        <ButtonList>
          <Button
            onClick={() => {
              return loginWithRedirect('login', redirectOpts);
            }}
            kind="tertiary"
            color="greyLight"
          >
            Log in
          </Button>
          <Button
            onClick={() => {
              return loginWithRedirect('signup', redirectOpts);
            }}
            style={{ whiteSpace: 'nowrap' }}
            kind="primary"
          >
            Sign up
          </Button>
        </ButtonList>
      </ActionCardBody>
    </Card>
  );
}

export function Footer() {
  const isSmallScreen = useMediaQuery(bluePlanetTheme.breakpoints.down('md'));
  const { loginWithRedirect } = useLoginWithRedirect();

  return (
    <Card
      padding={isSmallScreen ? 'default' : 'double'}
      style={{ marginTop: isSmallScreen ? '50vh' : '22vh' }}
      color="indigo"
    >
      <ActionCardBody>
        <IconAvatar color="white" className="u-content-spacing-right">
          <LockIcon />
        </IconAvatar>
        <p className="text-x-large text-weight-bold text-align-center">
          Unlock your potential network and extend your reach with relevant competence and capital, join now to build a
          relevant network and increase your chance of success.
        </p>
        <ActionText>
          Create an account to get access to their profile, pitch, live updates, insights and more.
        </ActionText>
        <ActionCardButtons>
          <ButtonList>
            <Button
              onClick={() => {
                return loginWithRedirect('signup');
              }}
              kind="primary"
            >
              Join to unlock
            </Button>
            <Button
              onClick={() => {
                return loginWithRedirect('login');
              }}
              kind="tertiary"
              color="greyLight"
            >
              Log in
            </Button>
          </ButtonList>
        </ActionCardButtons>
        <CardGrid
          className="u-section-spacing-top"
          mobile={{ columnCount: 1 }}
          tablet={{ columnCount: 2 }}
          desktop={{ columnCount: 3 }}
          xl={{ columnCount: 3 }}
          xxl={{ columnCount: 3 }}
        >
          <BlueCard title="Founders">
            Pitch your company and tell your story to an audience relevant for your success. Build relationships and
            secure your next funding round from investors looking for companies just like yours.
          </BlueCard>
          <BlueCard title="Industry professionals">
            Unlock the growth potential of your portfolio by facilitating efficient matchmaking, resource deployment,
            investment readiness, and create impactful relationships within thriving industry networks.
          </BlueCard>
          <BlueCard title="Investors">
            A versatile platform connecting you with companies and accelerators with a global network, streamlining
            investment decisions, and giving you access to a smart dealflow based on live founder data.
          </BlueCard>
        </CardGrid>
      </ActionCardBody>
    </Card>
  );
}
