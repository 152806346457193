import useSWR from 'hooks/useSWR';
import { CompanyListItem } from 'types/company';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from './companiesApi';
import { List } from 'types/api';

export const companiesKey = companiesAPIUrls.list();

export const useCompanies = () => {
  const swrResult = useSWR<List<CompanyListItem>>(companiesKey);
  return useSWRResource(swrResult);
};
