import React from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import Map, { Marker } from 'react-map-gl';
import { bluePlanetTheme } from 'ui/theme';
import { useMediaQuery } from '@mui/material';
import config from 'config';

interface LocationMapProps {
  latitude: number;
  longitude: number;
  zoom?: number;
}

export default function LocationMap({ latitude, longitude, zoom = 15 }: LocationMapProps) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const handleMapClick = () => {
    const googleMapsUrl = isMobile
      ? `geo:${latitude},${longitude}?q=${latitude},${longitude}`
      : `https://www.google.com/maps?q=${latitude},${longitude}`;

    window.open(googleMapsUrl, '_blank');
  };

  return (
    <div style={{ width: '100%', height: '300px' }}>
      <Map
        initialViewState={{
          latitude,
          longitude,
          zoom,
        }}
        style={{ width: '100%', height: '100%' }}
        dragPan={false}
        scrollZoom
        mapStyle="mapbox://styles/mapbox/light-v11"
        mapboxAccessToken={config.MAPBOX_API_TOKEN}
        onClick={handleMapClick}
        cursor="default"
      >
        <Marker latitude={latitude} longitude={longitude} color="red" />
      </Map>
    </div>
  );
}
