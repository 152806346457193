import config from 'config';

const baseUrl = `${config.COMPANIES_API_URL}/events`;

export default {
  get: (id: UUID) => `${baseUrl}/${id}`,
  getPreview: (id: UUID) => `${baseUrl}/${id}/preview`,
  getEventUsers: (id: UUID) => `${baseUrl}/${id}/users`,
  getApproved: () => `${baseUrl}/approved`,
};
