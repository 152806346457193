import React, { useRef, useState, useEffect } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import classnames from 'classnames';
import CancelIcon from 'ui/elements/icons/CloseIcon';
import CheckIcon from 'ui/elements/icons/CheckmarkIcon';
import RotateLeftIcon from 'ui/elements/icons/RotateLeftIcon';
import RotateRightIcon from 'ui/elements/icons/RotateRightIcon';
import { CloudinaryUploadResponse, CloudinaryUploadSettings } from 'types';
import styles from './styles.scss';
import Loading from 'ui/elements/Loading';
import uploadImage from './actions';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import IconButton from 'ui/elements/icons/IconButton';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import Card from 'ui/views/cards/Card';
import EditIcon from 'ui/elements/icons/EditIcon';

export type ImageUploadShape = 'round' | 'rectangular';
export type ImageEditorState = 'initial' | 'editing' | 'uploading' | 'confirmed';

interface Props {
  getUploadUrl?: (fileName: string) => Promise<CloudinaryUploadSettings>;
  onUploadSuccess?: (image: CloudinaryUploadResponse) => void;
  onUploadFailure?: (error: any) => void;
  onRemoveImage?: () => void;
  onConfirmImage?: () => void;
  onChange?: (avatarEditor: AvatarEditor, image?: File) => void;
  maxFileSize?: { bytes: number };
  imageUrl?: string;
  placeholder?: JSX.Element | ((openDropzone: () => void, height: number) => React.ReactNode);
  aspectRatio: number;
  width?: number;
  maxWidth?: number;
  className?: string;
  children?: (openDropzone: () => void) => React.ReactNode;
  shape?: ImageUploadShape;
  editorState?: ImageEditorState;
  isEditButtonVisible?: boolean;
  allowZoomOut?: boolean;
  style?: React.CSSProperties;
  align?: 'left' | 'center';
}

const ImageUpload: React.FC<Props> = ({
  getUploadUrl,
  onUploadSuccess,
  onUploadFailure,
  onConfirmImage,
  onChange,
  maxFileSize,
  imageUrl,
  placeholder,
  aspectRatio,
  width,
  isEditButtonVisible,
  maxWidth,
  className,
  children,
  shape,
  editorState: initialEditorState,
  align,
  allowZoomOut,
  style,
  onRemoveImage,
}) => {
  const avatarEditor = useRef<AvatarEditor | null>(null);
  const self = useRef<HTMLDivElement | null>(null);
  const dropzone = useRef<DropzoneRef | null>(null);

  const [image, setImage] = useState<File | null>(null);
  const [rotation, setRotation] = useState<number>(0);
  const [scale, setScale] = useState<number>(1);
  const [editorState, setEditorState] = useState<ImageEditorState>('initial');
  const [componentWidth, setComponentWidth] = useState<number>(0);

  useEffect(() => {
    if (self.current) {
      const calculatedWidth = width || self.current.clientWidth || 0;
      setComponentWidth(maxWidth ? Math.min(calculatedWidth, maxWidth) : calculatedWidth);
    }
  }, [width]);

  useEffect(() => {
    if (onChange && avatarEditor.current && image) {
      onChange(avatarEditor.current, image);
    }
  }, [onChange, avatarEditor, image, rotation, scale, componentWidth]);

  useEffect(() => {
    if (initialEditorState) {
      setEditorState(initialEditorState);
    }
  }, [initialEditorState]);

  const onDrop = (images: File[]) => {
    if (images.length > 0) {
      setImage(images[0]);
      setEditorState('editing');
    }
  };

  const cancel = () => {
    setImage(null);
    setRotation(0);
    setScale(1);
    setEditorState('initial');
  };

  const confirm = () => {
    setEditorState('confirmed');
    onConfirmImage && onConfirmImage();
  };

  const rotateLeft = () => {
    setRotation(rotation - 90);
  };

  const rotateRight = () => {
    setRotation(rotation + 90);
  };

  const handleScaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScale(Number(event.target.value));
  };

  // We need this because sometimes image updates slow, but we want to show the new image immediately
  const [displayImage, setDisplayImage] = useState(imageUrl);
  useEffect(() => {
    setDisplayImage(imageUrl);
  }, [imageUrl]);
  const currentImage = editorState === 'editing' ? image : displayImage;

  const upload = () => {
    if (getUploadUrl && image && avatarEditor.current) {
      uploadImage(
        getUploadUrl,
        avatarEditor.current?.getImage(),
        image,
        () => setEditorState('uploading'),
        cloudinary => {
          setImage(null);
          setDisplayImage(cloudinary.secure_url);
          setEditorState('confirmed');
          setScale(1);
          onUploadSuccess && onUploadSuccess(cloudinary);
        },
        error => {
          setEditorState('editing');
          onUploadFailure && onUploadFailure(error);
        },
      );
    }
  };

  const openDropzone = () => {
    dropzone.current && dropzone.current.open();
  };

  const height = Math.ceil(componentWidth / aspectRatio);

  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('md'));

  return (
    <div
      style={style}
      ref={self}
      className={classnames(styles.horizontalLayout, {
        [styles.center]: align === 'center',
        'u-veil': editorState === 'editing',
      })}
    >
      {editorState === 'editing' && isMobile && (
        <div style={{ zIndex: 999, position: 'fixed', bottom: 0, left: 0, width: '100%' }}>
          <Card className="u-flex u-flex-align-center">
            <IconButton onClick={rotateLeft}>
              <RotateLeftIcon />
            </IconButton>
            <IconButton onClick={rotateRight}>
              <RotateRightIcon />
            </IconButton>
            <input
              type="range"
              min={allowZoomOut ? '0.1' : '1'}
              max="3"
              value={scale}
              step="0.1"
              className={styles.slider}
              style={{
                height: '100%',
              }}
              onChange={handleScaleChange}
            />
            <IconButton onClick={cancel}>
              <CancelIcon />
            </IconButton>
            <IconButton onClick={getUploadUrl ? upload : confirm}>
              <CheckIcon />
            </IconButton>
          </Card>
        </div>
      )}
      {editorState === 'editing' && !isMobile && (
        <div className={styles.toolbar}>
          <div className={styles.action}>
            <IconButton onClick={rotateLeft}>
              <RotateLeftIcon />
            </IconButton>
            <IconButton onClick={rotateRight}>
              <RotateRightIcon />
            </IconButton>
          </div>
          <div className={classnames('u-flex', styles.action)}>
            <input
              type="range"
              min={allowZoomOut ? '0.1' : '1'}
              max="3"
              value={scale}
              step="0.1"
              className={styles.slider}
              onChange={handleScaleChange}
            />
          </div>
          <div className={styles.action}>
            <IconButton onClick={cancel}>
              <CancelIcon />
            </IconButton>
            <IconButton onClick={getUploadUrl ? upload : confirm}>
              <CheckIcon />
            </IconButton>
          </div>
        </div>
      )}
      <div className={styles.dropzoneContainer}>
        {onRemoveImage && imageUrl && editorState !== 'uploading' && editorState !== 'editing' && (
          <IconButton color="grey" className={styles.deleteButton} onClick={onRemoveImage}>
            <TrashIcon />
          </IconButton>
        )}
        {editorState !== 'uploading' && editorState !== 'editing' && imageUrl && isEditButtonVisible && (
          <IconButton
            color="white"
            className={styles.editButton}
            onClick={() => {
              dropzone.current?.open();
            }}
          >
            <EditIcon />
          </IconButton>
        )}

        <Dropzone
          ref={dropzone}
          disabled={editorState === 'editing' || editorState === 'uploading'}
          multiple={false}
          accept={{
            'image/*': ['.jpeg', '.png'],
          }}
          maxSize={maxFileSize && maxFileSize.bytes}
          onDrop={onDrop}
        >
          {dropzoneState => (
            <div
              {...dropzoneState.getRootProps()}
              className={classnames(className, styles.dropzone, {
                [styles.dropped]: editorState === 'editing' || editorState === 'uploading',
                [styles.dropzoneHover]: (editorState === 'initial' || editorState === 'confirmed') && imageUrl,
                [styles.round]: shape === 'round',
              })}
              style={{
                height: currentImage ? height : undefined,
                width: currentImage ? componentWidth : undefined,
              }}
            >
              {editorState === 'uploading' ? (
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <>
                  <input {...dropzoneState.getInputProps()} />
                  {currentImage ? (
                    editorState === 'editing' || editorState === 'confirmed' ? (
                      <AvatarEditor
                        ref={avatarEditor}
                        image={currentImage}
                        width={componentWidth}
                        height={height}
                        border={0}
                        scale={scale}
                        rotate={rotation}
                      />
                    ) : (
                      <img src={currentImage.toString()} height={height} width={componentWidth} />
                    )
                  ) : typeof placeholder === 'function' ? (
                    <div style={{ height, width: componentWidth }}>{placeholder(openDropzone, height)}</div>
                  ) : (
                    <div style={{ height, width: componentWidth }}>{placeholder}</div>
                  )}
                </>
              )}
            </div>
          )}
        </Dropzone>
      </div>
      {children && children(openDropzone)}
    </div>
  );
};

export default ImageUpload;
