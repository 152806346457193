import { withPagination } from 'apis/utils';
import config from 'config';
import { List, PaginatedResult, PaginationOptions } from 'types/api';
import { TinyCommunity } from 'types/company/community';
import { UserProfile } from 'types/user';
import useResource from 'util/resource/useResource';

const baseUrl = config.COMPANIES_API_URL;

export interface Enterprise {
  id: UUID;
  slug: string;
  name: string;
  logoURL: string;
}

export type ClientType = 'Client' | 'Guest';

export interface EnterpriseUser {
  userprofile: UserProfile;
  clientType: ClientType;
  isAdmin: boolean;
  acceptedSebTermsAt?: DateString;
  isMifidQualifiedProfessionalInvestor?: boolean;
  taxResidency?: string;
  communityMemberships: TinyCommunity[];
}

export interface EnterpriseCommunity {
  community: TinyCommunity;
  membersCount: number;
}

export function useEnterprises() {
  return useResource<List<Enterprise>>(`${baseUrl}/enterprises`);
}

export function useEnterpriseCommunities(enterpriseId: UUID) {
  return useResource<List<EnterpriseCommunity>>(`${baseUrl}/enterprises/${enterpriseId}/communities`);
}

export function useEnterpriseUsers(enterpriseId: UUID, paginationOptions: PaginationOptions) {
  return useResource<PaginatedResult<EnterpriseUser>>(
    withPagination(`${baseUrl}/enterprises/${enterpriseId}/users`, paginationOptions),
    { keepPreviousData: true },
  );
}
