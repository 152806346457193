import { Chip as MUIChip, ChipProps, alpha } from '@mui/material';
import cx from 'classnames';
import React, { CSSProperties } from 'react';
import { bluePlanetTheme } from 'ui/theme';
import { contentSpacing } from 'ui/theme/themeConstants';

export interface Props {
  color:
    | 'yellow'
    | 'yellow-light'
    | 'purple'
    | 'purple-light'
    | 'blue'
    | 'blue-medium'
    | 'blue-light'
    | 'orange'
    | 'orange-light'
    | 'green'
    | 'green-light'
    | 'green-medium'
    | 'red'
    | 'red-light'
    | 'indigo'
    | 'grey'
    | 'grey-medium'
    | 'grey-light'
    | 'white';
  label: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  ref?: ChipProps['ref'];
  title?: ChipProps['title'];
  onClick?: ChipProps['onClick'];
  onDelete?: ChipProps['onDelete'];
  fontSize?: 'small' | 'large';
  useShadow?: boolean;
  isRounded?: boolean;
  textTransform?: 'uppercase' | 'unset';
}

function getColor(variation: Props): { backgroundColor: string; color: string } {
  const palette = bluePlanetTheme.bluePlanetPalette;
  switch (variation.color) {
    case 'yellow-light':
      return { backgroundColor: palette.yellow.medium, color: palette.indigo.main };
    case 'yellow':
      return { backgroundColor: palette.yellow.main, color: palette.indigo.main };
    case 'purple-light':
      return { backgroundColor: palette.purple.light, color: palette.violet.main };
    case 'purple':
      return { backgroundColor: palette.purple.main, color: palette.offWhite };
    case 'purple-light':
      return { backgroundColor: palette.purple.light, color: palette.indigo.main };
    case 'blue':
      return { backgroundColor: palette.blue.main, color: palette.offWhite };
    case 'orange':
      return { backgroundColor: palette.orange.main, color: palette.offWhite };
    case 'orange-light':
      return { backgroundColor: palette.orange.light, color: palette.indigo.main };
    case 'green':
      return { backgroundColor: palette.green.main, color: palette.indigo.main };
    case 'green-light':
      return { backgroundColor: palette.green.light, color: palette.indigo.main || '' };
    case 'green-medium':
      return { backgroundColor: palette.green.medium, color: palette.indigo.main };
    case 'white':
      return { backgroundColor: palette.white, color: palette.black || '' };
    case 'red':
      return { backgroundColor: palette.red.main, color: palette.offWhite };
    case 'red-light':
      return { backgroundColor: palette.red.light, color: palette.indigo.main };
    case 'indigo':
      return { backgroundColor: palette.indigo.main, color: palette.offWhite };
    case 'grey':
      return { backgroundColor: palette.grey.main, color: palette.offWhite };
    case 'grey-medium':
      return { backgroundColor: palette.grey.medium, color: palette.indigo.main };
    case 'grey-light':
      return { backgroundColor: palette.grey.medium, color: palette.indigo.main };
    case 'blue-medium':
      return { backgroundColor: palette.blue.medium, color: palette.indigo.main };
    case 'blue-light':
    default:
      return { backgroundColor: palette.blue.veryLight!, color: palette.indigo.main };
  }
}

const Chip = (props: Props) => {
  return (
    <MUIChip
      sx={{
        ...getColor(props),
        fontSize: props.fontSize === 'small' ? '0.563rem' : props.fontSize === 'large' ? '0.8rem' : undefined,
        height: props.fontSize === 'small' ? '1rem' : undefined,
        filter: props.useShadow ? 'drop-shadow(0px 0px 11.25px rgba(0, 0, 0, 0.07));' : undefined,
        cursor: props.onClick ? 'pointer' : undefined,
        borderRadius: props.isRounded ? contentSpacing : undefined,
        '.MuiChip-label': {
          padding: `0 ${bluePlanetTheme.spacing(1)}`,
          textTransform: props.textTransform,
        },
        '.MuiChip-deleteIcon': {
          color: `${alpha(getColor(props).color, 0.4)}`,
          '&:hover': {
            color: getColor(props).color,
          },
          height: '16px',
          width: '16px',
        },
      }}
      className={cx(props.className)}
      label={props.label}
      onDelete={props.onDelete}
      onClick={props.onClick}
      ref={props.ref}
      style={props.style}
    />
  );
};
Chip.muiName = 'Chip';

export default Chip;
