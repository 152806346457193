import { useAuth0 } from '@auth0/auth0-react';
import { getAdminRights } from './util';
import { Auth0User } from 'types';

export default function useSuperAdmin() {
  const { user: auth0User }: { user?: Auth0User } = useAuth0();

  const adminRights = getAdminRights(auth0User);

  return {
    canViewBackOffice: adminRights.includes('backoffice'),
    canReadEverything: adminRights.includes('read-everything'),
    canWriteEverything: adminRights.includes('write-everything'),
  };
}
