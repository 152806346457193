import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import React from 'react';
import { DetailedUserProfile, investmentExperiences, investorTypes } from 'types/user';
import IndustryChip from 'ui/domain/Chips/IndustryChip';
import SkillChip from 'ui/domain/Chips/SkillChip';
import ChipList from 'ui/elements/Chip/ChipList';
import SectionHeading from 'ui/elements/SectionHeading';
import { bluePlanetTheme } from 'ui/theme';
import { quarterSpacing } from 'ui/theme/themeConstants';
import Card from 'ui/views/cards/Card';
import TruncatedList from 'util/components/TruncatedList';
import { formatCurrencyRange, getCurrencySymbol } from 'util/currency';
import { formatThousands } from 'util/numbers';

const Label = styled.label`
  display: block;
  margin-bottom: ${quarterSpacing};
`;

export default function InvestorProfile({ user }: { user: DetailedUserProfile }) {
  const hasInvestmentPreferences =
    user.isInvestor &&
    (user.investorType != null ||
      user.industryInvestmentPreferences.length > 0 ||
      (user.investmentExperience && user.investmentExperience.length > 0) ||
      user.investmentCapacity?.value ||
      user.preferredInvestmentSize.rangeFrom ||
      user.preferredInvestmentSize.rangeTo);

  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const chipsLimit = isMobile ? 4 : Infinity;

  return (
    <Card className="u-content-spacing-top">
      {hasInvestmentPreferences && (
        <>
          <SectionHeading heading="Investment profile"></SectionHeading>
          {user.investorType && (
            <div className="u-section-spacing-right u-content-spacing-bottom">
              <Label className="text-metadata">Type of investor</Label>
              <span className="text-h4">{investorTypes[user.investorType].name}</span>
            </div>
          )}
          <div className="u-flex u-content-spacing-bottom">
            {user.preferredInvestmentSize && (
              <div className="u-section-spacing-right">
                <Label className="text-metadata">Investment size</Label>
                <span className="text-h4">
                  {formatCurrencyRange(
                    user.preferredInvestmentSize.currency,
                    user.preferredInvestmentSize.rangeFrom,
                    user.preferredInvestmentSize.rangeTo,
                  )}
                </span>
              </div>
            )}

            {(user.investmentCapacity?.value ?? 0) > 0 && (
              <div>
                <Label className="text-metadata">Capacity next 3 years</Label>
                <span className="text-h4">
                  {getCurrencySymbol(user.investmentCapacity?.currency)}
                  {formatThousands(user.investmentCapacity?.value)}
                </span>
              </div>
            )}
          </div>

          {user.industryInvestmentPreferences.length > 0 && (
            <div className="u-content-spacing-bottom">
              <h6 className="text-metadata u-half-spacing-bottom">Industry investment preference</h6>
              <ChipList>
                <TruncatedList
                  values={user.industryInvestmentPreferences}
                  limit={chipsLimit}
                  renderItem={label => <IndustryChip label={label.name} />}
                  renderExpandButton={(label, onClick) => <IndustryChip label={label.name} onClick={onClick} />}
                />
              </ChipList>
            </div>
          )}

          {user.investmentExperience && user.investmentExperience.length > 0 && (
            <div>
              <h6 className="text-metadata u-half-spacing-bottom">Investment experience</h6>
              <ChipList>
                <TruncatedList
                  values={user.investmentExperience.map(x => ({ name: x, label: investmentExperiences[x] }))}
                  limit={chipsLimit}
                  renderItem={label => <SkillChip label={label.label} />}
                  renderExpandButton={(label, onClick) => <SkillChip label={label.label} onClick={onClick} />}
                />
              </ChipList>
            </div>
          )}
        </>
      )}
      {user.investmentPreference && (
        <div className="u-section-spacing-top">
          <h6 className="text-metadata u-half-spacing-bottom">Investment preferences and involvement </h6>
          <p className="text-body">{user.investmentPreference}</p>
        </div>
      )}
    </Card>
  );
}
