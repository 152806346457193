import React from 'react';
import SvgIcon, { IconProps } from './SvgIcon';

export default function NoteIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m12.95 7.655 4.773 1.272m-5.796 2.525 2.386.636m-2.267 6.517.954.255c2.7.719 4.05 1.079 5.114.468 1.063-.61 1.425-1.953 2.148-4.638l1.024-3.796c.723-2.685 1.085-4.028.47-5.085-.613-1.058-1.963-1.417-4.663-2.137l-.955-.254c-2.7-.72-4.05-1.08-5.113-.469C9.96 3.56 9.6 4.902 8.876 7.587l-1.023 3.797c-.723 2.685-1.085 4.027-.471 5.084.614 1.058 1.964 1.418 4.664 2.137Z" />
      <path d="m12.07 21.585-.953.26c-2.694.733-4.04 1.1-5.101.477-1.061-.623-1.422-1.991-2.144-4.729l-1.02-3.871c-.723-2.737-1.083-4.106-.47-5.184.529-.933 1.687-.9 3.187-.9" />
    </SvgIcon>
  );
}
