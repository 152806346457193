import React, { useEffect } from 'react';
import OnYourAgenda from './sections/messages/OnYourAgenda';
import { DashboardData } from 'apis/DashboardAPI';
import Pulse, { ActivityType } from './sections/pulse/Pulse';
import { scrollToTop } from 'util/scroll';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import { sectionSpacing } from 'ui/theme/themeConstants';
import { bluePlanetTheme } from 'ui/theme';
import styled from '@emotion/styled';
import FeaturedCompanyMatchesForCurrentUser from 'pages/Discover/FeaturedCompanyMatchesForCurrentUser';
import { getOrUndefined } from 'util/resource';
import CompleteProfileMessage from './sections/messages/CompleteProfileMessage';
import { useCompanies } from 'apis/CompanyAPI/companies/useCompanies';
import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import Resources from 'util/resource/Resources';
import { SelfUserProfile } from 'types/user';
import { CompanyListItem } from 'types/company';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 800px 600px;
  grid-template-areas:
    'messages messages'
    'top sidebar';
  column-gap: ${sectionSpacing};

  ${bluePlanetTheme.breakpoints.down(1700)} {
    grid-template-columns: 800px 3fr;
  }

  ${bluePlanetTheme.breakpoints.down(1600)} {
    column-gap: 2rem;
  }

  ${bluePlanetTheme.breakpoints.down(1500)} {
    grid-template-columns: 4fr 3fr;
    column-gap: 1rem;
  }

  ${bluePlanetTheme.breakpoints.down('lg')} {
    grid-template-columns: 100%;
    grid-template-areas: 'messages' 'sidebar' 'top';
    gap: 0;
  }
`;

const MINIMUM_COMPANY_COUNT_IN_NETOWRK_FOR_NETWORK_AS_DEFAULT = 15;

function getSelectableActivityTypes(user: SelfUserProfile, companies: CompanyListItem[]) {
  const isNetworkAboveThreshold = companies.length >= MINIMUM_COMPANY_COUNT_IN_NETOWRK_FOR_NETWORK_AS_DEFAULT;
  return [
    {
      type: 'All',
      condition: user.canBrowsePlatform,
    },
    {
      type: 'Suggestions',
      condition: user.canBrowsePlatform && user.isInvestor && !isNetworkAboveThreshold,
    },
    {
      type: 'From my network',
      condition: true,
    },
    {
      type: 'From my collections',
      condition: true,
    },
  ]
    .filter(it => it.condition)
    .map(it => it.type) as ActivityType[];
}

export function Home({
  hundredPitchesMessage,
  dashboardData,
}: {
  hundredPitchesMessage: { type: 'is-founder-in-100-pitches'; communitySlug: string } | undefined;
  dashboardData: DashboardData;
}): React.ReactNode {
  const messages = hundredPitchesMessage
    ? [hundredPitchesMessage, ...(dashboardData.messages ?? [])]
    : dashboardData.messages;

  const { resource: userProfileResource } = useSelfUserProfile();

  const companies = useCompanies();
  const communities = useCommunities();

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <GridContainer>
      {messages.length > 0 && (
        <div style={{ gridArea: 'messages' }}>
          <OnYourAgenda messages={messages} />
        </div>
      )}
      <div style={{ gridArea: 'sidebar' }}>
        {messages.length > 0 && <CompleteProfileMessage messages={messages} />}
        {getOrUndefined(userProfileResource)?.canBrowsePlatform && (
          <FeaturedCompanyMatchesForCurrentUser className="u-section-spacing-bottom" />
        )}
      </div>
      <div style={{ gridArea: 'top' }}>
        <Resources resources={[userProfileResource, companies.resource, communities.resource]}>
          {([user, companies, communities]) => {
            const selectableActivityTypes = getSelectableActivityTypes(user, companies.values);

            const initialActivityType =
              selectableActivityTypes.includes('From my network') &&
              (communities.values.length > 0 ||
                companies.values.length >= MINIMUM_COMPANY_COUNT_IN_NETOWRK_FOR_NETWORK_AS_DEFAULT)
                ? 'From my network'
                : 'All';

            return <Pulse activityTypes={selectableActivityTypes} initialActivityType={initialActivityType} />;
          }}
        </Resources>
      </div>
    </GridContainer>
  );
}
