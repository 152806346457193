import { communitiesApi, communityAPIUrls } from 'apis/CompanyAPI/communities/communitiesApi';
import React from 'react';
import Attachments from 'ui/domain/Attachments/Attachments';
import { EventDTO, EventUser } from 'ui/domain/Event/types';
import HtmlContent from 'ui/elements/HtmlContent';
import { getOrUndefined } from 'util/resource';
import styles from './styles.scss';
import SectionHeading from 'ui/elements/SectionHeading';
import LocationMap from '../shared/Location/LocationMap';
import Card from 'ui/views/cards/Card';
import { communityUrls, organizationUrls } from 'urls';
import Button from 'ui/elements/buttons/Button';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import useCommunityRole from 'hooks/useCommunityRole';
import UserElement from 'ui/domain/Users/UserElement';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import useResource from 'util/resource/useResource';
import { TinyCommunity } from 'types/company/community';
import ClickableCard from 'ui/views/cards/ClickableCard';
import Avatar from 'ui/elements/avatars/Avatar';
import OrganizationAPI from 'apis/OrganizationAPI';
import { TinyOrganizationDTO } from 'types/organization';
import DeleteDialog from 'ui/modules/dialogs/DeleteDialog';
import useRoute from 'hooks/useRoute';
import useOrganizationRole from 'hooks/useOrganizationRole';
import { invalidate } from 'hooks/useSWR';

export function ShowEvent({ event }: { event: EventDTO }) {
  const [isAdminUserDialogOpen, setIsAdminUserDialogOpen] = React.useState<EventUser | undefined>(undefined);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);

  const communityEventAPI = communitiesApi.events;
  const organizationEventAPI = OrganizationAPI.events;
  const { push } = useRoute();

  const { resource: organizationResource } = useResource<TinyOrganizationDTO>(
    event.organizationId ? OrganizationAPI.getById(event.organizationId) : undefined,
  );
  const organization = getOrUndefined(organizationResource);
  const { isWriteAdmin: canEditOrganization } = useOrganizationRole(organization?.slug);

  const { resource: communityResource } = useResource<TinyCommunity>(
    event.communityId ? communityAPIUrls.get(event.communityId) : undefined,
  );
  const community = getOrUndefined(communityResource);
  const canEditCommunity = useCommunityRole(community?.slug).canEditCommunity;

  const canEditEvent = community ? canEditCommunity : organization ? canEditOrganization : false;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.left}>
          <HtmlContent className={styles.eventContent} embeddingIsAllowed>
            {event.content}
          </HtmlContent>
          {event.attachments.length > 0 && (
            <Attachments
              contentId={event.id}
              attachmentIds={event.attachments}
              listAttachments={() =>
                event.communityId
                  ? communityEventAPI.listAttachments(event.communityId!, event.id)
                  : event.organizationId
                    ? organizationEventAPI.listAttachments(event.organizationId!, event.id)
                    : Promise.resolve({ documents: [] })
              }
              downloadAttachment={(_, documentId) =>
                event.communityId
                  ? communityEventAPI.download(event.communityId!, event.id, documentId)
                  : event.organizationId
                    ? organizationEventAPI.download(event.organizationId!, event.id, documentId)
                    : Promise.resolve({ path: '', filename: '', contentType: '' })
              }
              isPreview={false}
              imgCount={0}
              onReadMore={() => {}}
            />
          )}
        </div>

        <div className={styles.right}>
          <SectionHeading heading="Contact us" />
          <div className="u-half-spacing-x u-content-spacing-y">
            {event.users
              .filter(user => user.isAdmin)
              .map(user => (
                <div className="u-quarter-spacing" key={user.user.id}>
                  <Card onClick={() => setIsAdminUserDialogOpen(user)}>
                    <UserElement user={user.user} />
                  </Card>
                </div>
              ))}

            {event.richLocation && (
              <div className="u-quarter-spacing">
                <LocationMap latitude={event.richLocation?.latitude} longitude={event.richLocation?.longitude} />
              </div>
            )}

            <Card className="u-quarter-spacing">
              <span className="text-subheading-2">LOCATION:</span>
              <span className="text-small u-quarter-spacing-left">
                {event.richLocation ? event.richLocation.description : event.location}
              </span>
            </Card>
          </div>

          <SectionHeading heading="Partners and hosts" />
          <div className="u-half-spacing-x u-content-spacing-y">
            {community && (
              <div className="u-quarter-spacing" key={community.id}>
                <ClickableCard
                  title={<span>{community.name}</span>}
                  padding="half"
                  href={communityUrls.overview(community.slug)}
                  avatar={<Avatar imageUrl={community.logoURL} userName={community.name} />}
                >
                  <span className="text-metadata">Community</span>
                </ClickableCard>
              </div>
            )}

            {organization && (
              <div className="u-quarter-spacing" key={organization.id}>
                <ClickableCard
                  title={<span>{organization.name}</span>}
                  padding="half"
                  href={organizationUrls.view(organization.slug)}
                  avatar={<Avatar imageUrl={organization.logoURL} userName={organization.name} />}
                >
                  <span className="text-metadata">Organization</span>
                </ClickableCard>
              </div>
            )}

            {event.organizations &&
              event.organizations.map(org => (
                <div className="u-quarter-spacing" key={org.organization.id}>
                  <ClickableCard
                    title={<span>{org.organization.name}</span>}
                    padding="half"
                    href={organizationUrls.view(org.organization.slug)}
                    avatar={<Avatar imageUrl={org.organization.logoURL} userName={org.organization.name} />}
                  >
                    <span className="text-metadata">Organization</span>
                  </ClickableCard>
                </div>
              ))}
          </div>
        </div>
      </div>

      <UserProfileDialog
        isOpen={isAdminUserDialogOpen?.user.cwUserId !== undefined}
        cwUserId={isAdminUserDialogOpen?.user.cwUserId ?? ''}
        onClose={() => setIsAdminUserDialogOpen(undefined)}
      />

      {canEditEvent && (
        <Button kind="primary" color="red" onClick={() => setIsDeleteDialogOpen(true)}>
          <div className="u-flex-align-center u-flex--gap-quarter">
            <TrashIcon fontSize="small" />
            Delete
          </div>
        </Button>
      )}

      <DeleteDialog
        isOpen={isDeleteDialogOpen && canEditEvent}
        onClose={() => setIsDeleteDialogOpen(false)}
        onDelete={
          isDeleteDialogOpen && canEditEvent
            ? event.communityId
              ? () => communitiesApi.events.delete(event.communityId!, event.id)
              : event.organizationId
                ? () => OrganizationAPI.events.delete(event.organizationId!, event.id)
                : undefined
            : undefined
        }
        onSuccess={
          community
            ? () => {
                push(communityUrls.overview(community.slug, 'events'));
                invalidate(communityAPIUrls.events.list(community.slug));
              }
            : organization
              ? () => {
                  push(organizationUrls.view(organization.slug));
                  invalidate(OrganizationAPI.getBySlug(organization.slug));
                }
              : undefined
        }
        title="Delete event"
        resourceBeingDeleted={event.title}
      />
    </>
  );
}
