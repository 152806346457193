import { Menu, MenuItem, PopoverOrigin, useMediaQuery } from '@mui/material';
import React, { CSSProperties } from 'react';
import { bluePlanetTheme } from 'ui/theme';
import IconButton from 'ui/elements/icons/IconButton';
import EllipsisVertical from 'ui/elements/icons/EllipsisVertical';
import { IconColor } from 'ui/elements/icons/SvgIcon';
import Tooltip from 'ui/elements/Tooltip';
import DrawerBottom, { DrawerItem } from 'ui/elements/DrawerBottom';
import { contentSpacing, quarterSpacing } from 'ui/theme/themeConstants';
import styled from '@emotion/styled';

export type PopMenuItem =
  | {
      onClick?: () => void;
      icon?: JSX.Element;
      text: JSX.Element | string;
      selected?: boolean;
      onMouseEnter?: () => void;
      onMouseLeave?: () => void;
      backgroundColor?: string;
      spacing?: 'default' | 'compact';
    }
  | 'divider';

interface Props {
  style?: CSSProperties;
  className?: string;
  items?: PopMenuItem[];
  icon?: JSX.Element;
  tooltip?: string;
  iconColor?: IconColor;
  staticItems?: React.ReactNode;
  renderAnchor?: (onClick: (e: React.MouseEvent<HTMLElement>) => void, isOpen: Boolean) => JSX.Element;
  onOpen?: () => void;
  onClose?: () => void;
  anchorOrigin?: PopoverOrigin;
}

const Divider = styled.hr`
  margin: ${quarterSpacing} ${contentSpacing};
`;

export default function PopMenu({
  style,
  className,
  icon,
  tooltip,
  iconColor,
  renderAnchor,
  staticItems,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  items = [],
  onOpen,
  onClose,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  function handleClose() {
    setAnchorEl(null);
    onClose?.();
  }

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    if (!anchorEl) onOpen?.();
  }

  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  return (
    <div className={className}>
      {renderAnchor ? (
        renderAnchor(handleClick, isOpen)
      ) : tooltip ? (
        <Tooltip title={tooltip}>
          <span>
            <IconButton color={iconColor ?? 'indigo'} onClick={handleClick}>
              {icon ?? <EllipsisVertical />}
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <IconButton color={iconColor ?? 'indigo'} onClick={handleClick}>
          {icon ?? <EllipsisVertical />}
        </IconButton>
      )}

      {isMobile && (
        <DrawerBottom isOpen={isOpen} onClose={handleClose}>
          {items.map((item, i) =>
            item === 'divider' ? (
              <Divider key={i} />
            ) : (
              <DrawerItem
                itemSpacing={item.spacing}
                backgroundColor={item.backgroundColor}
                key={i}
                prefix={item.icon}
                onClick={
                  item.onClick
                    ? () => {
                        item.onClick?.();
                        handleClose();
                      }
                    : undefined
                }
              >
                {item.text}
              </DrawerItem>
            ),
          )}
        </DrawerBottom>
      )}
      {!isMobile && (
        <Menu
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={{ horizontal: anchorOrigin?.horizontal, vertical: 'top' }}
          slotProps={{ paper: { style } }}
        >
          {items.map((item, i) =>
            item === 'divider' ? (
              <Divider key={i} />
            ) : (
              <MenuItem
                key={i}
                data-open={!!open || undefined}
                className={className}
                sx={{
                  backgroundColor: item.backgroundColor,
                  padding: item.spacing === 'compact' ? `${quarterSpacing} ${contentSpacing}` : undefined,
                  cursor: item.onClick ? 'pointer' : 'default',
                  '&:hover': item.onClick ? undefined : { backgroundColor: 'transparent' },
                }}
                onMouseEnter={item.onMouseEnter}
                onMouseLeave={item.onMouseLeave}
                selected={item.selected}
                onClick={
                  item.onClick
                    ? () => {
                        item.onClick?.();
                        handleClose();
                      }
                    : undefined
                }
              >
                {item.icon && <span className="u-flex u-half-spacing-right">{item.icon}</span>}
                {item.text}
              </MenuItem>
            ),
          )}
          {staticItems && <div style={{ padding: bluePlanetTheme.spacing(1) }}>{staticItems}</div>}
        </Menu>
      )}
    </div>
  );
}
