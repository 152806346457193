import EventAPI from 'apis/EventAPI';
import React from 'react';
import { EventDTO } from 'ui/domain/Event/types';
import Dialog, { Content, Title } from 'ui/views/dialogs/Dialog';
import Resource from 'util/resource/Resource';
import useResource from 'util/resource/useResource';
import { ShowEvent } from './ShowEvent';

export default function EventDialog({ eventId, onClose }: { eventId: UUID; onClose: () => void }) {
  const { resource } = useResource<EventDTO>(EventAPI.get(eventId));
  return (
    <Dialog open onClose={onClose}>
      <Resource resource={resource}>
        {event => (
          <>
            <Title onClose={onClose}>{event.title}</Title>
            <Content>
              <ShowEvent event={event} />
            </Content>
          </>
        )}
      </Resource>
    </Dialog>
  );
}
