import React, { useState } from 'react';
import { OnCommentDeleted } from './CommentsContainer';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import { AuthorImage, AuthorProfile, asUserAuthor } from 'domain/shared/author';
import { AuthorLink, AuthorRole } from 'ui/domain/Byline';
import { insertLinksForUrlsWithoutAnchorTags } from 'util/stringUtils';
import Timestamp from 'ui/elements/Timestamp';
import useDialogHandler from 'hooks/useDialogHandler';
import styled from '@emotion/styled';
import { contentSpacing, halfSpacing } from 'ui/theme/themeConstants';
import ConfirmDialog from 'ui/modules/dialogs/ConfirmDialog';
import Button from 'ui/elements/buttons/Button';
import LinkButton from 'ui/elements/buttons/LinkButton';
import { bluePlanetTheme } from 'ui/theme';
import cx from 'classnames';
import styles from './styles.scss';
import HtmlContentWithMentions from 'domain/shared/HtmlContentWithMentions';
import useSuperAdmin from 'auth/useSuperAdmin';
import { useLocation } from 'react-router';
import useScrollToElement from 'hooks/useScrollToElement';

interface CommentCardProps {
  userProfiles: AuthorProfile[];
  comment: {
    id: UUID;
    content: string;
    createdAt: string;
    creatorCwUserId: string;
    responseTo?: string;
    deleted: boolean;
  };
  showResponseForm?: boolean;
  responses?: number;
  onReply?: () => void;
  onDeleteComment: OnCommentDeleted;
  loggedInUserCwUserId?: UUID;
  isResponse?: boolean;
  padding?: 'default' | 'none';
}

const Message = styled.div(
  (props: { isResponse: boolean; padding: 'default' | 'none' }) => `
  display: flex;
  max-width: 550px;
  ${props.padding === 'none' ? 'padding: 0;' : `padding: ${halfSpacing} ${contentSpacing};`}
  min-width: 100%;
  
  ${
    props.isResponse
      ? `
        margin: ${halfSpacing} 50px 0;
        min-width: calc(100% - 50px);
        `
      : ''
  }
`,
);

const DeleteLinkButton = styled(LinkButton)`
  color: ${bluePlanetTheme.bluePlanetPalette.grey.main} !important;

  &:hover,
  &:focus {
    color: ${bluePlanetTheme.bluePlanetPalette.red.main} !important;
  }
`;

export default function CommentCard(props: CommentCardProps) {
  const userProfile = props.userProfiles.find(cu => cu.cwUserId === props.comment.creatorCwUserId);
  const { canViewBackOffice: canAccessBackoffice } = useSuperAdmin();
  const isCommenter = props.comment.creatorCwUserId === props.loggedInUserCwUserId;
  const canDelete = !props.comment.deleted && (isCommenter || canAccessBackoffice);
  const userAuthor = asUserAuthor(userProfile);

  const [userDialogId, setUserDialogId] = useState('');
  const onAuthorClick = userProfile?.cwUserId ? () => setUserDialogId(userProfile?.cwUserId) : undefined;
  const dialogHandler = useDialogHandler();

  const { hash } = useLocation();

  useScrollToElement(props.comment.id, !!hash && hash.substring(1) === props.comment.id);

  const onConfirmDelete = () => {
    dialogHandler.close();
    props.onDeleteComment(props.comment.id, props.comment.responseTo);
  };

  return (
    <>
      <Message
        id={props.comment.id}
        className={hash && hash.substring(1) === props.comment.id ? styles.commentHighlight : undefined}
        isResponse={props.isResponse || false}
        padding={props.padding ?? 'default'}
      >
        <div>
          <AuthorImage size={40} onClick={onAuthorClick} author={userAuthor} />
        </div>
        <div className="text-small u-flex-grow u-half-spacing-left">
          <div style={{ lineHeight: 1 }}>
            <span className="text-weight-medium text-small text-link-dark">
              <AuthorLink className="text-align-left" name={userAuthor.name} onClick={onAuthorClick} />
            </span>
            <span className="text-metadata">
              <AuthorRole author={userAuthor} />
            </span>
          </div>
          <div>
            <HtmlContentWithMentions
              className={cx(styles.comment, props.comment.deleted ? 'text-metadata text-italic' : undefined)}
            >
              {insertLinksForUrlsWithoutAnchorTags(props.comment.content)}
            </HtmlContentWithMentions>
            <div className="text-metadata u-quarter-spacing-top u-flex">
              <Timestamp timestamp={props.comment.createdAt} />
              {canDelete && (
                <DeleteLinkButton size="small" className="u-half-spacing-left" onClick={() => dialogHandler.open()}>
                  Delete
                </DeleteLinkButton>
              )}
              {props.onReply && !props.showResponseForm && (
                <LinkButton size="small" className="u-half-spacing-left" onClick={props.onReply}>
                  Reply
                </LinkButton>
              )}
            </div>
          </div>
        </div>
      </Message>
      <ConfirmDialog
        isOpen={dialogHandler.isOpen}
        onClose={dialogHandler.close}
        title="You are about to remove this comment"
        confirmButton={
          <Button color="red" kind="primary" onClick={onConfirmDelete}>
            Yes, remove
          </Button>
        }
        dismissLabel="No, cancel"
      >
        <HtmlContentWithMentions className={'text-italic'}>
          {insertLinksForUrlsWithoutAnchorTags(props.comment.content)}
        </HtmlContentWithMentions>
        <p className="u-section-spacing-top">Are you sure?</p>
      </ConfirmDialog>
      <UserProfileDialog isOpen={!!userDialogId} cwUserId={userDialogId} onClose={() => setUserDialogId('')} />
    </>
  );
}
