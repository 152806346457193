import { useRef, useEffect } from 'react';

export default function useDebouncedIntersectionObserver(
  pageRefs: React.RefObject<HTMLElement>[],
  onPageChange: (num: number) => void,
  debounceDelay: number,
) {
  // Use number for timeoutRef in browser environments
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    if (pageRefs.length === 0) return;

    // Initialize intersectionRatios with all elements set to 0
    const intersectionRatios = new Map<HTMLElement, number>(
      pageRefs
        .map(ref => ref.current)
        .filter((el): el is HTMLElement => el !== null && el instanceof HTMLElement)
        .map(el => [el, 0]),
    );

    const observerOptions = {
      // Reduced number of thresholds for better performance
      threshold: [0, 0.25, 0.5, 0.75, 1],
    };

    const intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        // Type assertion to HTMLElement since we've filtered appropriately
        intersectionRatios.set(entry.target as HTMLElement, entry.intersectionRatio);
      });

      // Determine the element with the highest intersection ratio
      let maxRatio = 0;
      let mostVisibleTarget: HTMLElement | null = null;

      intersectionRatios.forEach((ratio, target) => {
        if (ratio > maxRatio) {
          maxRatio = ratio;
          mostVisibleTarget = target;
        }
      });

      if (mostVisibleTarget) {
        const index = pageRefs.findIndex(ref => ref.current === mostVisibleTarget);
        if (index !== -1) {
          if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
          }
          timeoutRef.current = window.setTimeout(() => {
            onPageChange(index + 1);
          }, debounceDelay);
        }
      }
    }, observerOptions);

    // Observe all elements
    pageRefs.forEach(ref => {
      if (ref.current) {
        intersectionObserver.observe(ref.current);
      }
    });

    // Cleanup on unmount or when dependencies change
    return () => {
      intersectionObserver.disconnect();
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [pageRefs, onPageChange, debounceDelay]);
}
