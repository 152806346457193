import { useFundingStages } from 'apis/CompanyAPI/labels/useFundingStages';
import { useIndustries } from 'apis/CompanyAPI/labels/useIndustries';
import { useStages } from 'apis/CompanyAPI/labels/useStages';
import { OnboardingPart, OnboardingStep } from 'apis/OnboardingAPI';
import BasicCompanyProfileForm from 'domain/onboarding/company/Wizard/BasicCompanyProfileForm';
import IndustryForm from 'domain/onboarding/company/Wizard/IndustryForm';
import KpisForm from 'domain/onboarding/company/CompanyKpis/KpisForm';
import StagesForm from 'domain/onboarding/company/Wizard/StagesForm';
import React, { useEffect, useState } from 'react';
import { CompanyIndustryReach, CompanyProfile, ICompany } from 'types/company';
import Wizard, { OnboardingStage } from 'ui/modules/wizards/Wizard';
import { DialogActions } from 'ui/views/dialogs/Dialog';
import { track } from 'util/analytics';
import { getOrElse } from 'util/resource';
import ButtonList from 'ui/elements/buttons/ButtonList';
import Button from 'ui/elements/buttons/Button';
import { useCompanyProfile } from 'apis/CompanyAPI/companies/useCompanyProfile';
import Resource from 'util/resource/Resource';
import { companiesApi, companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import Avatar from 'ui/elements/avatars/Avatar';
import PostUpdateForm from './PostUpdateForm';
import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import { userDashboardKey } from 'pages/Dashboard/useUserDashboardData';
import { invalidate } from 'hooks/useSWR';
import AvatarGroup from 'ui/elements/avatars/AvatarGroup';
import TextAvatarGroup from 'ui/elements/avatars/TextAvatarGroup';
import AiOnboarding from './AIOnboarding';
import { get } from 'apis/ApiBase';
import InviteConnectionForm from 'domain/shared/InviteConnectionForm';
import { getPublicCompanyUrl } from 'pages/CompanySettings/CompanySharing';
import { useCompanyLastUpdated } from 'domain/companies/profile/LastUpdated';

export default function CompanyOnboardingWizard({
  company,
  wizard,
  setStepCompleted,
  onClose,
}: {
  wizard: OnboardingPart;
  company: ICompany;
  setStepCompleted: (step: OnboardingStep) => void;
  onClose: (status: 'Skipped' | 'Completed') => void;
}) {
  const { resource, mutate } = useCompanyProfile(company.slug);

  return (
    <Resource resource={resource} renderLoading="Nothing">
      {companyProfile => (
        <CompanyWizard
          setCompanyProfile={mutate}
          companyProfile={companyProfile}
          onboarding={wizard}
          setStepCompleted={setStepCompleted}
          onClose={onClose}
        />
      )}
    </Resource>
  );
}

function IndustryReach({ industryReach }: { industryReach: CompanyIndustryReach }) {
  return (
    <TextAvatarGroup>
      <span className="u-quarter-spacing-right">Nice! Preview and prepare your profile to connect with</span>
      <AvatarGroup max={5} size={30}>
        {industryReach.imageUrls.map(url => (
          <Avatar key={url} imageUrl={url} borderColor="grey" />
        ))}
      </AvatarGroup>
      <span>
        and {industryReach.reach.upperLimit} other investors, early stage companies and corporates within your
        industries
      </span>
    </TextAvatarGroup>
  );
}

function CompanyWizard({
  companyProfile,
  setCompanyProfile,
  onboarding,
  setStepCompleted,
  onClose,
}: {
  companyProfile: CompanyProfile;
  setCompanyProfile: (companyProfile: CompanyProfile) => void;
  onboarding: OnboardingPart;
  setStepCompleted: (step: OnboardingStep) => void;
  onClose: (status: 'Skipped' | 'Completed') => void;
}) {
  const { resource: industriesResource } = useIndustries();
  const { resource: fundingStagesResource } = useFundingStages();
  const { resource: stagesResource } = useStages();
  const { resource: communitesResource } = useCommunities();

  const industries = getOrElse(industriesResource, { parents: [], children: [] });
  const productStages = getOrElse(stagesResource, { values: [], industryDependent: [] });
  const fundingStages = getOrElse(fundingStagesResource, { values: [] });

  const { setLastUpdated } = useCompanyLastUpdated(companyProfile.id);

  useEffect(() => {
    track('company-onboarding-started');
  }, [onboarding]);

  const completedStages = onboarding.steps.filter(step => step.isCompleted).map(step => step.step);

  type Steps =
    | 'Welcome'
    | 'IndustryAndBusinessFocus'
    | 'ProductAndFundingStage'
    | 'Kpis'
    | 'BasicProfile'
    | 'InviteYourStakeholders'
    | 'PostFirstUpdate';

  function onCloseWizard(status: 'Skipped' | 'Completed', reload = true) {
    onClose(status);
    if (reload) {
      invalidate(userDashboardKey);
    }
    track('company-onboarding-completed');
  }

  const [useAi, setUseAi] = useState(false);

  const [industryReachResource] = useResourceLegacy(() => {
    if (companyProfile.industries.values.length > 0) {
      return get<CompanyIndustryReach>(companiesAPIUrls.insights.industryReach(companyProfile.id));
    } else {
      return Promise.resolve({ reach: { lowerLimit: 0 }, imageUrls: [] });
    }
  }, [companyProfile.industries.values]);

  const industryReach = getOrElse(industryReachResource, { reach: { lowerLimit: 0 }, imageUrls: [] });
  const stages: OnboardingStage<Steps>[] = [
    {
      name: 'IndustryAndBusinessFocus',
      title: 'What is your industry and business focus?',
      subtitle: 'Add as many areas as you want, choose at least one category',
      content: ({ onComplete }) => (
        <IndustryForm
          industries={industries.parents}
          companyProfile={companyProfile}
          setUseAiMode={setUseAi}
          onComplete={selectedIndustries => {
            setCompanyProfile({ ...companyProfile, industries: { values: selectedIndustries } });
            setStepCompleted('IndustryAndBusinessFocus');
            onComplete();
          }}
          onClose={() => onCloseWizard('Skipped')}
        />
      ),
    },
    {
      name: 'ProductAndFundingStage',
      title: 'What is your current product and funding stage?',
      content: ({ onComplete, onBack }) => (
        <StagesForm
          stages={productStages}
          fundingStages={fundingStages.values}
          companyProfile={companyProfile}
          onComplete={(selectedStage, selectedFundingStage) => {
            setCompanyProfile({
              ...companyProfile,
              stages: { values: selectedStage ? [selectedStage] : [] },
              fundingStage: selectedFundingStage,
            });
            setStepCompleted('ProductAndFundingStage');
            onComplete();
          }}
          onBack={onBack}
          onClose={() => onCloseWizard('Skipped')}
        />
      ),
    },
    {
      name: 'Kpis',
      title: 'What are your relevant KPIs for traction?',
      content: ({ onComplete, onBack }) => (
        <>
          <KpisForm
            companyId={companyProfile.id}
            onKpiUploaded={() => {
              setStepCompleted('Kpis'); // we don't reload onboarding here because this status is calculated by async events backend, and might not be updated by the time the user gets the new status
            }}
          />
          <DialogActions align="space-between">
            <ButtonList>
              <Button kind="primary" type="submit" onClick={onComplete}>
                Next
              </Button>
              <Button onClick={onBack} kind="primary" color="greyLight">
                Back
              </Button>
            </ButtonList>
            <Button onClick={() => onCloseWizard('Skipped', false)} kind="tertiary" color="grey">
              Pick it up later
            </Button>
          </DialogActions>
        </>
      ),
    },
    {
      name: 'BasicProfile',
      title: 'Build your profile',
      subtitle: industryReach.reach.upperLimit ? <IndustryReach industryReach={industryReach} /> : undefined,
      content: ({ onComplete, onBack }) => (
        <BasicCompanyProfileForm
          companyProfile={companyProfile}
          setCompanyProfile={setCompanyProfile}
          onComplete={onComplete}
          onClose={() => onCloseWizard('Skipped')}
          onBack={onBack}
        />
      ),
    },
    {
      name: 'InviteYourStakeholders',
      title: 'Share and connect',
      subtitle:
        'Invite your current connections to help you grow, including early angels, board members, key team members, advisors, and shareholders. You can also modify these connections later if needed.',
      content: ({ onComplete, onBack }) => (
        <InviteConnectionForm
          onComplete={() => {
            setLastUpdated(new Date());
            onComplete();
          }}
          onClose={() => onCloseWizard('Skipped')}
          onBack={onBack}
          inviteFunction={emails =>
            companiesApi.userInvites.invite(
              companyProfile.id,
              emails.map(email => ({ role: 'prospective_investor', email })),
            )
          }
          inviteLabelText={'Invite company connections by email'}
          shareLabelText={'Or invite connections by link'}
          footerText={`Connections get access to your company profile and future updates. You can easily change permissions to
          investor and board member access for confidential information at a later stage. If your connections are
          already on the platform they will be notified directly. Others will recieve an invitation to join your
          company.`}
          publicUrl={getPublicCompanyUrl(companyProfile.slug)}
          isLastStep={false}
        />
      ),
    },
    {
      name: 'PostFirstUpdate',
      title: 'Create your first update for future connections',
      subtitle: 'Make people welcome by posting relevant content. Here are some suggestions to get started...',
      content: ({ onComplete, onBack }) => (
        <PostUpdateForm
          communities={getOrElse(communitesResource, { values: [] }).values}
          companyProfile={companyProfile}
          onBack={onBack}
          onClose={() => onCloseWizard('Skipped')}
          onComplete={() => {
            onComplete();
            invalidate(userDashboardKey);
            onCloseWizard('Completed');
          }}
        />
      ),
    },
  ];

  if (useAi) {
    return (
      <AiOnboarding
        companyProfile={companyProfile}
        onClose={() => onClose('Skipped')}
        onComplete={() => onClose('Completed')}
        onUseManualInput={() => setUseAi(false)}
      />
    );
  }

  return <Wizard completedStages={completedStages} stages={stages} />;
}
